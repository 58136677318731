<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <!-- <div style="margin-bottom: 10px; margin-left: 15px; color: red;">
      <label for="" v-if="locationId === null"
        >Please select Location to save or edit bid</label
      >
    </div> -->
    <div>
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label for="font">RFP Number</label>
            <md-input
              v-model="rfpHdrDetails.rfp_number"
              :title="rfpHdrDetails.rfp_number"
              disabled
            >
            </md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label for="font">Descrption</label>
            <md-input
              v-model="rfpHdrDetails.description"
              :title="rfpHdrDetails.description"
              disabled
            >
            </md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label>Start Date</label>
            <md-input
              v-model="rfpHdrDetails.start_date"
              :title="rfpHdrDetails.start_date"
              disabled
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label>End Date</label>
            <md-input
              v-model="rfpHdrDetails.end_date"
              :title="rfpHdrDetails.end_date"
              disabled
            ></md-input>
          </md-field>
        </div>
        <!-- <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <b-icon
              icon="pin-map-fill"
              aria-hidden="true"
              style="margin-top: -20px; font-size: 20px; color: #EB3573"
              v-if="locationId === null"
              class="icon"
              :class="{ 'icon-animate': animate }"
            ></b-icon>
            <label for="filter" class="text-black mandatory">Location</label>
            <md-input
              v-model="rfpHdrDetails.location"
              :title="rfpHdrDetails.location"
              readonly
              @click="showHideLocationDetails(true)"
            ></md-input>
          </md-field>
        </div> -->
        <!-- <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label>Version</label>
            <md-input
              v-model="rfpHdrDetails.version"
              :title="rfpHdrDetails.version"
              readonly
              disabled
            ></md-input>
          </md-field>
        </div> -->
        <div style="margin-top: 20px; display: flex;" v-if="excluded">
          <b-form-checkbox
            v-model="excluded"
            :value="true"
            disabled
            :unchecked-value="false"
            style="display: inline"
          >
          </b-form-checkbox>
          <div>Excluded</div>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label>Bid Status</label>
            <md-input
              v-model="rfpHdrDetails.bidStatus"
              :title="rfpHdrDetails.bidStatus"
              readonly
              disabled
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field>
            <label>Payment Terms</label>
            <md-input
              v-model="rfpHdrDetails.paymentTerms"
              :title="rfpHdrDetails.paymentTerms"
              readonly
              disabled
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-button
            v-if="rfpHdrDetails.bidStatus !== 'SUBMIT'"
            @click="getOpenKmAuthenticateUploadDocument()"
            class="md-raised md-rose"
            :disabled="
              rfpHdrDetails.bidStatus === null || rfpHdrDetails.bidStatus === ''
            "
            >Upload Document</md-button
          >
          <md-button
            v-if="rfpHdrDetails.bidStatus == 'SUBMIT'"
            @click="getOpenKmAuthenticateUploadDocument()"
            class="md-raised md-rose"
            >View Document</md-button
          >
        </div>
        <br />
        <br />
        <div class="md-layout-item md-small-size-100 md-size-20">
          <b-button
            style="border: none; background-color: green; color: white; padding: 6px 30px; margin-top: 5px; cursor: pointer;"
            @click="openReviewModal()"
            >REVIEW & SUBMIT</b-button
          >
        </div>
        <div
          class="md-layout-item md-small-size-100 md-size-15"
          style="margin-left: 15px;"
        ></div>
      </div>
    </div>
    <div v-if="showDocumentModal">
      <div style="display: flex;">
        <div style="width: 50%; border: 1px solid">
          <p style="font-weight: bold; margin-left: 10px;">Header Attachments</p>
          <uploadDocument
            :wfHdrId="documentId"
            :openKmAuth="openKmAuth"
            :propMenuId="menuSubmenuRfpHeader.menuId"
            :PropSubMenuId="menuSubmenuRfpHeader.submenuId"
            :approval_status="poHeaderDetails.approval_status"
            formName="rfpItems"
          />
        </div>
        <div style="width: 50%; border: 1px solid">
          <p style="font-weight: bold; margin-left: 10px;">Terms & Conditions</p>
          <uploadDocument
            :wfHdrId="termId"
            :openKmAuth="openKmAuth"
            :propMenuId="menuSubmenuTermsAndCondition.menuId"
            :PropSubMenuId="menuSubmenuTermsAndCondition.submenuId"
            :approval_status="poHeaderDetails.approval_status"
            formName="rfp"
          />
        </div>
      </div>
    </div>
    <!-- <div>
      <label for="">Select Location</label>
      <div>
        <b-table
          striped
          hover
          bordered
          outlined
          sticky-header
          :items="rpfLocationData"
          :fields="rpfLocationField"
          show-empty
          class="bg-white"
        >
          <template #cell(select)="data">
            <b-form-radio
              :key="data.index"
              name="esc-radios"
              v-model="locationSelected"
              :value="data.index"
              @change="locationRowSelected(data.item)"
            ></b-form-radio>
          </template>
          <template #cell(copy_bid)="data">
            <md-button
              class="md-raised md-warning search-btn"
              :disabled="locationSelected !== data.index"
              @click="showHideLocations(true)"
              >Copy Bid</md-button
            >
          </template>
        </b-table>
      </div>
    </div> -->
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <!-- <md-card> -->
      <div style="margin-top: 20px;">
        <b-table
          striped
          hover
          bordered
          outlined
          sticky-header
          :items="rpfLineData"
          :fields="rpfLineField"
          show-empty
          class="bg-white"
        >
          <template #cell(item_description)="data">
            <span
              style="cursor:context-menu;"
              :title="data.item.item_description"
              >{{ truncateComment(data.item.item_description, 15) }}</span
            >
          </template>
          <template #cell(long_desc)="data">
            <span style="cursor:context-menu;" :title="data.item.long_desc">{{
              truncateComment(data.item.long_desc, 15)
            }}</span>
          </template>
          <template #head(unit_price)="">
            <label for="">Unit Price</label>
          </template>
          <template #cell(unit_price)="data">
            <md-field>
              <md-input
                type="number"
                :disabled="!editMode || excluded || locationId === null"
                v-model="data.item.unit_price"
                @change="qtyTwoDecimalValue(data.index)"
                @input="
                  checkUnitPriceApplicable(data.item.unit_price, data.index)
                "
              ></md-input>
            </md-field>
          </template>
          <template #cell(remarks)="data">
            <md-field>
              <md-input
                :disabled="!editMode || excluded || locationId === null"
                v-model="data.item.remarks"
              ></md-input>
            </md-field>
          </template>
          <template #cell(attribute)="data">
            <md-button
              :disabled="!data.item.bid_id"
              class="md-raised md-warning search-btn"
              @click="showHideAttributepDetails(true, data.item)"
              >Add Attribute</md-button
            >
          </template>
          <template #cell(include_item)="data">
            <b-form-checkbox
              v-model="data.item.include_item"
              :value="true"
              :unchecked-value="false"
              style="display: inline;"
              disabled
            >
            </b-form-checkbox>
          </template>
          <template #cell(price)="data">
            <md-button
              @click="showHidePriceModal(true, data.item)"
              class="md-raised md-info search-btn"
              >Price</md-button
            >
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
        <div
          v-if="rpfLineData.length && totalRows <= perPage"
          class="pagination-count"
        >
          <!-- <p>
            Showing
            {{ totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1 }}
            to {{ totalRows }} of {{ totalRows }} entries
          </p> -->
        </div>
        <!-- <div v-else-if="rpfLineData.length" class="pagination-count">
          <p>
            Showing {{ perPage * (currentPage - 1) + 1 }} to
            {{
              perPage * currentPage >= totalRows
                ? totalRows
                : perPage * currentPage
            }}
            of {{ totalRows }} entries
          </p>
        </div> -->
      </div>
      <!-- </md-card> -->
    </div>
    <!-- <b-modal
      v-model="showAttributeDetails"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Attribute Details"
      @hidden="hideAttributepDetails()"
    >
      <div class="md-layout-item">
        <b-table
          style="max-height:400px;"
          striped
          hover
          bordered
          outlined
          sticky-header
          :items="attributeData"
          :fields="attributeField"
          show-empty
        >
          <template #cell(rating)="data">
            <md-field style="min-height: auto;">
              <md-input v-model="data.item.rating" v-numericOnly></md-input>
            </md-field>
          </template>
          <template #head(remarks)="">
            <label
              for="remarks"
              :class="attributeData.length > 0 ? 'col-small mandatory' : ''"
              >Remarks</label
            >
          </template>
          <template #cell(remarks)="data">
            <md-field>
              <md-input
              :disabled="!editMode"
                v-model="data.item.remarks"
                :title="data.item.remarks"
              ></md-input>
            </md-field>
          </template>
        </b-table>
      </div>
      <div
        v-if="attributeData.length > 0"
        class="md-layout-item md-small-size-100 md-size-25"
        style="float:right;"
      >
        <md-button
          class="md-raised md-primary search-btn"
          @click="postItemAttributeData()"
          :disabled="!editMode"
          >Save</md-button
        >
      </div>
    </b-modal> -->
    <b-modal
      v-model="showLocationDetails"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Location"
      @hidden="showHideLocationDetails(false)"
    >
      <template>
        <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="rpfLocationData"
            :fields="rpfLocationField"
            show-empty
            class="bg-white"
            style="max-height:calc(100vh - 220px)"
            @row-clicked="locationRowSelected"
          >
          </b-table>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="showCopyLocation"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="md"
      title="Copy Location"
      @hidden="showHideCopyLocation(false)"
    >
      <div>
        <md-field>
          <label style="font-size:15px; font-weight:bold"
            >Selected Version</label
          >
          <div>
            <md-input
              v-model="selectedVersion.text"
              class="md-primary md-small-size-200 md-size-80"
              :title="selectedVersion.text"
              readonly
              @click="showHideVersion(true)"
            />
            <span
              style="margin-top: -15px;"
              aria-hidden="true"
              class="clearable-icon"
              v-if="selectedVersion.text"
              @click="clearValues('selectedVersion')"
              >×</span
            >
          </div>
        </md-field>
        <label style="font-size:15px;">Selected Location</label>
        <div
          style="min-width:10%; float:right;"
          class="md-layout-item md-small-size-100 md-size-30"
        >
          <md-button
            class="md-raised md-primary search-btn"
            @click="showHideLocations(true)"
            >Add Location</md-button
          >
        </div>
        <div>
          <md-chips
            style="background-color:lightgrey; height: 180px; padding-left:10px; padding-top:4px;"
            class="md-primary pulse-on-error tags auto-expand-tags"
            v-model="selectedLocation"
            md-deletable
            md-check-duplicated
            onkeydown="return false;"
          >
          </md-chips>
        </div>
        <div>
          <md-button
            class="md-raised md-primary search-btn"
            @click="saveCopyBid()"
            >SAVE</md-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="showHideLocationsModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="lg"
      title="Copy Location"
      @hidden="showHideLocations(false)"
    >
      <div>
        <div class="md-layout-item">
          <b-table
            style="max-height:400px;"
            striped
            hover
            outlined
            sticky-header
            :items="copyLocationData"
            :fields="copyLocationField"
            show-empty
            class="bg-white"
          >
            <template #head(selectBox)="data">
              <div>
                <b-form-checkbox
                  v-model="selectAllCheckBox"
                  :value="true"
                  :unchecked-value="false"
                  style="display: inline"
                  @change="selectAllBoxChecked($event)"
                >
                </b-form-checkbox>
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #cell(selectBox)="data">
              <b-form-checkbox
                v-model="data.item.selectBox"
                :value="true"
                :unchecked-value="false"
                @change="selectBoxChecked($event, data.index, data.item)"
              >
              </b-form-checkbox>
            </template>
          </b-table>
        </div>
      </div>
      <div>
        <md-button
          class="md-raised md-primary search-btn"
          @click="saveCopyBid()"
          >Save</md-button
        >
      </div>
    </b-modal>
    <b-modal
      v-model="showHideVersionModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="md"
      title="Copy Version"
      @hidden="showHideVersion(false)"
      @row-clicked="locationRowSelected"
    >
      <div>
        <div class="md-layout-item">
          <b-table
            style="max-height:400px;"
            striped
            hover
            outlined
            bordered
            sticky-header
            :items="copyVersionData"
            :fields="copyversionField"
            show-empty
            class="bg-white"
            @row-clicked="versionSelected"
          >
            <!-- <template #head(selectBox)="data">
              <div>
                <b-form-checkbox
                  v-model="selectAllCheckBox"
                  :value="true"
                  :unchecked-value="false"
                  style="display: inline"
                  @change="selectAllBoxChecked($event)"
                >
                </b-form-checkbox>
                <span>{{ data.label }}</span> -->
            <!-- </div>
            </template>
            <template #cell(selectVersion)="data">
              <b-form-checkbox
                v-model="data.item.selectVersion"
                :value="true"
                :unchecked-value="false"
                @change="selectVersionChecked($event, data.index, data.item)"
              >
              </b-form-checkbox>
            </template> -->
          </b-table>
        </div>
      </div>
      <div>
        <md-button
          class="md-raised md-primary search-btn"
          @click="saveVersion()"
          >SAVE</md-button
        >
      </div>
    </b-modal>
    <b-modal
      v-model="showReviewModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="Review"
      @hidden="showHideReviewModal(false)"
    >
      <template>
        <RFPDetailsReview
          :rfpHdrDetails="rfpHdrDetails"
          :bidHeaderId="bidHeaderId"
        />
      </template>
    </b-modal>
    <!-- <b-modal
      v-model="showDocumentModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Attachment"
      @hidden="showHideDocumentModal(false)"
    >
      <uploadDocument
        :wfHdrId="documentId"
        :openKmAuth="openKmAuth"
        :propMenuId="menuId"
        :PropSubMenuId="subMenuId"
        :approval_status="poHeaderDetails.approval_status"
        formName="rfp"
      />
    </b-modal> -->
    <b-modal
      v-model="showOpenTermsCondition"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Terms & Conditions"
      @hidden="closeTermsCondition(false)"
    >
      <template>
        <b-row>
          <md-button
            class="md-raised md-primary search-btn"
            @click="getOpenKmAuthenticate('tnc')"
            >View Document</md-button
          >
        </b-row>

        <b-row md="12">
          <b-col md="12">
            <label for="filter">Terms & Conditions</label>
            <vue-ckeditor
              type="classic"
              :editors="editors"
              :config="editorConfig"
              v-model="summary"
              :title="summary"
            ></vue-ckeditor>
          </b-col>
        </b-row>
        <div v-if="summary">
          <b-row style="margin-top: 50px; margin-left: 10px;">
            <b-form-checkbox
              v-model="checkboxFlag"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
            <span>Accept terms and Conditions</span>
          </b-row>
          <b-row>
            <md-button
              class="md-primary"
              :disabled="!checkboxFlag"
              @click="submitBtn()"
              >Accept</md-button
            >
          </b-row>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="openSaveModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="md"
      title="Save Items"
      @hidden="closeSaveModal(false)"
    >
      <template>
        <div
          style="display: flex; justify-content: center;  flex-wrap: wrap; margin-top: 100px; margin-right: auto; margin-left: auto; gap: 1rem; "
        >
          <p>Some Items are excluded. Do you still want to save the Bid?</p>
          <md-button class="md-raised md-accent" @click="saveData()"
            >Yes</md-button
          >
          <md-button class="md-primary" @click="closeModal()">No</md-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="showAttributeDetails"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Attribute Details"
      @hidden="hideAttributepDetails()"
    >
      <div class="md-layout-item">
        <b-table
          style="max-height:400px;"
          striped
          hover
          bordered
          outlined
          sticky-header
          :items="attributeData"
          :fields="attributeField"
          show-empty
        >
          <template #cell(rating)="data">
            <md-field style="min-height: auto;">
              <md-input v-model="data.item.rating" v-numericOnly></md-input>
            </md-field>
          </template>
          <template #head(remarks)="">
            <label
              for="remarks"
              :class="attributeData.length > 0 ? 'col-small mandatory' : ''"
              >Remarks</label
            >
          </template>
          <template #cell(remarks)="data">
            <md-field>
              <md-input
                :disabled="!editMode || rfpHdrDetails.bidStatus === 'SUBMIT'"
                v-model="data.item.remarks"
                :title="data.item.remarks"
                maxlength="100"
              ></md-input>
            </md-field>
          </template>
        </b-table>
      </div>
      <div
        v-if="attributeData.length > 0"
        class="md-layout-item md-small-size-100 md-size-45"
      >
        <md-button
          v-if="locationIndex === 0 && priceData.length > 1"
          :disabled="!editMode || rfpHdrDetails.bidStatus === 'SUBMIT'"
          class="md-raised md-primary search-btn"
          @click="copyItemAttributesData()"
          >Copy Attributes to All</md-button
        >
        <md-button
          :disabled="!editMode || rfpHdrDetails.bidStatus === 'SUBMIT'"
          class="md-raised md-primary search-btn"
          @click="postItemAttributeData()"
          >Save</md-button
        >
      </div>
    </b-modal>

    <b-modal
      v-model="showPriceModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Price"
      @hidden="showHidePriceModal(false, null)"
    >
      <div>
        <div class="overlayLoader" v-if="loader">
          <md-progress-spinner
            :md-diameter="100"
            class="md-accent spinner-postion"
            :md-stroke="7"
            md-mode="indeterminate"
          ></md-progress-spinner>
        </div>
        <div style="display: flex">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label for="font">Item Code</label>
              <md-input
                v-model="priceModalHeaderData.item_code"
                :title="priceModalHeaderData.item_code"
                disabled
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-30">
            <md-field>
              <label for="font">Item Description</label>
              <md-input
                v-model="priceModalHeaderData.item_description"
                :title="priceModalHeaderData.item_description"
                disabled
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-10">
            <md-field>
              <label for="font">UOM</label>
              <md-input
                v-model="priceModalHeaderData.uom"
                :title="priceModalHeaderData.uom"
                disabled
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-10">
            <md-field>
              <label for="font">Qty</label>
              <md-input
                v-model="priceModalHeaderData.quantity"
                :title="priceModalHeaderData.quantity"
                disabled
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-35">
            <md-field>
              <label for="font">Item Specification</label>
              <md-input
                v-model="priceModalHeaderData.item_long_description"
                :title="priceModalHeaderData.item_long_description"
                disabled
              >
              </md-input>
            </md-field>
          </div>
        </div>
        <div>
          <b-table
            style="max-height:400px;"
            striped
            hover
            bordered
            outlined
            sticky-header
            :items="priceData"
            :fields="priceField"
            show-empty
          >
            <template #cell(attributes)="data">
              <md-button
                @click="showHideAttributepDetails(true, data.item, data.index)"
                class="md-raised md-info search-btn"
                style="min-height: auto;"
                :disabled="
                  data.item.bid_id === 0 || rfpHdrDetails.bidStatus === null
                "
              >
                Attributes
              </md-button>
            </template>
            <template #cell(unit_price)="data">
              <md-field>
                <md-input
                  type="number"
                  :disabled="!editMode || excluded"
                  v-model="data.item.unit_price"
                  @change="qtyTwoDecimalValue(data.index)"
                  @input="
                    checkUnitPriceApplicable(data.item.unit_price, data.index)
                  "
                ></md-input>
              </md-field>
            </template>
            <template #cell(remarks)="data">
              <md-field>
                <md-input
                  :disabled="!editMode || excluded"
                  v-model="data.item.remarks"
                  :title="data.item.remarks"
                >
                </md-input>
              </md-field>
            </template>
          </b-table>
        </div>
        <div>
          <md-button
            v-if="priceData.length > 1"
            class="md-raised md-success search-btn"
            @click="copyUnitPriceToAll()"
            >Copy Unit Price To All</md-button
          >
          <b-button
            style="border: none; background-color: green; color: white; padding: 6px 30px; margin-top: 5px; margin-left: 5px; margin-right: 5px; cursor: pointer;"
            @click="saveRfpData()"
            :disabled="!editMode"
            >SAVE</b-button
          >
          <md-button
            v-if="!editMode"
            class="md-raised md-primary search-btn ml-2"
            @click="alterEditMode()"
            >Edit</md-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="showUploadDocumentModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Upload Document"
      @hidden="showHideUploadDocumentModal(false)"
    >
      <uploadDocument
        v-if="rfpHdrDetails.bidStatus === 'SUBMIT'"
        :wfHdrId="bidHeaderId"
        :openKmAuth="openKmAuth"
        :propMenuId="menuSubmenuBidHeader.menuId"
        :PropSubMenuId="menuSubmenuBidHeader.submenuId"
        :approval_status="poHeaderDetails.approval_status"
        formName="rfpQuotationView"
      />
      <uploadDocument
        v-if="rfpHdrDetails.bidStatus !== 'SUBMIT'"
        :wfHdrId="bidHeaderId"
        :openKmAuth="openKmAuth"
        :propMenuId="menuSubmenuBidHeader.menuId"
        :PropSubMenuId="menuSubmenuBidHeader.submenuId"
        :approval_status="poHeaderDetails.approval_status"
        formName="rfpQuotation"
      />
    </b-modal>
    <valueSetModal
      v-if="showValueSetModal"
      @selectedvalueSet="selectedvalueSet"
      @closeValueSetModal="closeValueSetModal"
    ></valueSetModal>
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
import appStrings from "../../../utility/string.utility";
import RFPDetailsReview from "./RFPDetailsReview.vue";
import uploadDocument from "../../uploadDocument.vue";
import VueCkeditor from "vue-ckeditor5";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "PoLineDetails",
  components: {
    RFPDetailsReview,
    uploadDocument,
    VueCkeditor,
    "vue-ckeditor": VueCkeditor.component,
  },
  watch: {
    currentPage: function() {
      this.getVendorPoLineDetailsById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorPoLineDetailsById();
    },
  },
  props: ["rowDetails"],
  data() {
    return {
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      rfpHdrDetails: {},
      rpfLineField: [
        // {
        //   key: "include_item",
        // },
        {
          key: "item_code",
        },
        {
          key: "item_description",
          label: "Description",
        },
        {
          key: "item_long_description",
          label: "Item Specification",
        },
        {
          key: "uom",
          label: "UOM",
        },
        {
          key: "quantity",
          label: "Qty.",
        },
        // {
        //   key: "last_unit_price",
        // },
        // {
        //   key: "unit_price",
        // },
        // {
        //   key: "remarks",
        // },
        // {
        //   key: "attribute",
        // },
        {
          key: "price",
        },
      ],
      rpfLineData: [],
      poHdrId: null,
      showAttributeDetails: false,
      showLocationDetails: false,
      showCopyLocation: false,
      copyLocationArray: [],
      attributeData: [],
      attributeField: [
        {
          key: "attribute_code",
        },
        {
          key: "attribute_description",
          label: "Attribute Description",
        },
        // {
        //   key: "attribute_specification",
        // },
        // {
        //   key: "rating",
        // },
        {
          key: "remarks",
        },
      ],
      chips: [],
      showValueSetModal: false,
      rpfLocationData: [],
      rpfLocationField: [
        {
          key: "select",
        },
        {
          key: "bid_loc_status",
          label: "Bid Loc Status",
          class: "col-fix",
        },
        {
          key: "country",
        },
        {
          key: "region",
        },
        {
          key: "state",
        },
        {
          key: "cost_centre",
        },
        {
          key: "copy_bid",
        },
      ],
      totalLocationDetailsRows: null,
      locationId: null,
      bidHeaderId: 0,
      rfpItemId: null,
      bidId: null,
      showHideLocationsModal: false,
      copyLocationData: [],
      copyLocationField: [
        {
          key: "selectBox",
          label: "Select",
          stickyColumn: true,
          variant: "info",
        },
        {
          key: "bid_loc_status",
        },
        {
          key: "country",
        },
        {
          key: "region",
        },
        {
          key: "state",
        },
        {
          key: "cost_centre",
        },
      ],
      selectedLocation: [],
      selectedLocationId: [],
      selectAllCheckBox: false,
      showReviewModal: false,
      editMode: true,
      excluded: false,
      excludeAllItems: false,
      excludeLocationFlag: null,
      excludeLocationId: null,
      openKmAuth: null,
      showDocumentModal: false,
      venInvoiceId: 0,
      subMenuId: 0,
      poHeaderDetails: {
        approval_status: "DRAFT",
      },
      showOpenTermsCondition: false,
      documentId: 0,
      termId: 0,
      summary: null,
      editors: {
        classic: ClassicEditor,
      },
      editorConfig: {
        readOnly: true,
        toolbar: [],
      },
      openSaveModal: false,
      checkboxFlag: false,
      termsFlag: false,
      totalUploadedDocumentRows: null,
      selectedVersion: {
        value: null,
        text: null,
      },
      copyVersionData: [],
      copyversionField: [
        {
          key: "negotiation_seq",
          label: "Round",
        },
        {
          key: "version",
        },
      ],
      showHideVersionModal: false,
      animate: false,
      locationSelected: null,
      reviewAndSubmitFlag: false,
      showPriceModal: false,
      priceModalHeaderData: {},
      priceData: [],
      priceField: [
        {
          key: "location_name",
        },
        {
          key: "unit_price",
        },
        {
          key: "remarks",
        },
        {
          key: "attributes",
        },
        // {
        //   key: 'fill_all'
        // }
      ],
      locationIndex: null,
      showUploadDocumentModal: false,
      menuSubmenuRfpHeader: {
        menuId: null,
        submenuId: null,
      },
      menuSubmenuBidHeader: {
        menuId: null,
        submenuId: null,
      },
      menuSubmenuTermsAndCondition: {
        menuId: null,
        submenuId: null,
      },
    };
  },
  created() {
    this.eventBus.$on(
      "totalUploadedDocumentRows",
      (totalUploadedDocumentRows) => {
        this.totalUploadedDocumentRows = totalUploadedDocumentRows;
      }
    );
  },
  mounted() {
    this.setMenuSubmenuId();
    setInterval(() => {
      this.animate = !this.animate;
    }, 2000);
    if (this.rowDetails) {
      this.fillRecordForPoDetails(this.rowDetails);
      this.documentId = this.rfpHdrDetails.rfp_header_id;
      this.getOpenKmAuthenticate("attachment");
    }
    this.getLocationDetails();
  },
  methods: {
    fillRecordForPoDetails(item) {
      this.rfpHdrDetails = {
        // bidStatus: item.status,
        rfp_number: item.rfp_number,
        description: item.description,
        start_date: item.start_date,
        end_date: item.end_date,
        rfp_header_id: item.rfp_header_id,
        rfp_vendor_id: item.rfp_vendor_id,
        user_id: item.user_id,
        negotiation_hdr_id: item.negotiation_hdr_id,
        negotiation_vendor_id: item.negotiation_vendor_id,
        paymentTerms: item.payment_terms,
      };
      this.termId = item.terms_id;
      if (this.termId === null) {
        this.termsFlag = true;
      }
      if (this.rfpHdrDetails.bidStatus === "SUBMIT") {
        this.editMode = false;
      }
      this.getRfpLineData(this.rfpHdrDetails);
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    showHideAttributepDetails(flag, item, index) {
      this.locationIndex = index;
      this.rfpItemId = item.rfp_item_id;
      this.bidId = item.bid_id;
      this.getItemAttributeLineData();
      // this.getItemAttributeData();

      this.showAttributeDetails = flag;
    },
    showHideLocationDetails(flag) {
      if (flag) {
        this.getLocationDetails();
      }
      this.showLocationDetails = flag;
    },
    showHideCopyLocation(flag) {
      this.showCopyLocation = flag;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null,
        };
        this.eventBus.$emit("valueSetCode", vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_APPROVAL_STATUS_VSET
      ) {
        const isChipDuplicate = this.chips.some(
          (ele) => ele === item.value_code
        );
        if (!isChipDuplicate) {
          this.chips.push(item.value_code);
        } else {
          alert("Location already selected");
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    // formatCurr(num) {
    //   return commonHelper.formatAmount(Number(num));
    // },
    // getVendorPoLineDetailsById() {
    //   const payload = {
    //     _page: this.currentPage - 1,
    //     _limit: this.perPage,
    //     po_release_id: this.poLineRowDetails.po_release_id,
    //     po_header_id: this.poHdrId,
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch("vendor/getVendorPoLineDetailsById", payload)
    //     .then((resp) => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         const result = resp.data.data.data.page;
    //         this.rpfLineData = result.map((elem) => {
    //           elem.need_by_date = commonHelper.formatDate(elem.need_by_date);
    //           elem.promised_date = commonHelper.formatDate(elem.promised_date);
    //           return elem;
    //         });
    //         this.totalRows = resp.data.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    getLocationDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        rfp_header_id: this.rfpHdrDetails.rfp_header_id,
        rfp_vendor_id: this.rfpHdrDetails.rfp_vendor_id,
        negotiation_hdr_id: this.rfpHdrDetails.negotiation_hdr_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRfpLocationDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.rfpHdrDetails.bidStatus = result[0].status;
            this.rpfLocationData = result;
            this.bidHeaderId = result[0].bid_hdr_id;
            this.totalLocationDetailsRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getExcludeLocationDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        rfp_header_id: this.rfpHdrDetails.rfp_header_id,
        rfp_vendor_id: this.rfpHdrDetails.rfp_vendor_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRfpLocationDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.rpfExcludeLocationData = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    locationRowSelected(item) {
      this.excludeLocationId = item.exc_loc_id;
      if (this.excludeLocationId !== null) {
        this.excluded = true;
      } else {
        this.excluded = false;
      }
      // if (item.state !== null) {
      //   this.rfpHdrDetails.location = item.state;
      // } else if (item.state === null && item.region !== null) {
      //   this.rfpHdrDetails.location = item.region;
      // } else if (item.state === null && item.region === null) {
      //   this.rfpHdrDetails.location = item.country;
      // }
      if (item.cost_centre !== null) {
        this.rfpHdrDetails.location = item.cost_centre;
      } else if (item.cost_centre === null && item.state !== null) {
        this.rfpHdrDetails.location = item.state;
      } else if (
        item.cost_centre === null &&
        item.state === null &&
        item.region !== null
      ) {
        this.rfpHdrDetails.location = item.region;
      } else if (
        item.cost_centre === null &&
        item.state === null &&
        item.region === null
      ) {
        this.rfpHdrDetails.location = item.country;
      }
      this.locationId = item.location_id;
      this.showLocationDetails = false;
      if (item.bid_hdr_id === null) {
        this.bidHeaderId = 0;
      } else {
        this.bidHeaderId = item.bid_hdr_id;
        this.getRfpItemData();
      }
    },
    excludeLocationRowSelected() {
      let payload = {};
      if (!this.excluded) {
        payload = {
          bid_hdr_id: this.bidHeaderId,
          location_id: this.locationId,
          insert: "1",
        };
      } else {
        payload = {
          bid_hdr_id: this.bidHeaderId,
          location_id: this.locationId,
          insert: "0",
        };
      }

      this.loader = true;
      this.$store
        .dispatch("vendor/postExcludeLocation", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            this.responseMsg = resp.data.message;
            this.makeToast(this.responseMsg, "success");
            this.excluded = !this.excluded;
            setTimeout(() => {
              this.excluded = JSON.parse(JSON.stringify(this.excluded));
            }, 0);
            this.getRfpItemData();
          } else {
            this.responseMsg = resp.data.message;
            this.makeToast(this.responseMsg, "danger");
          }
        })
        .catch((err) => {
          this.loader = false;
          this.makeToast(err.message, "danger");
        });
      // this.excludeLocation = item.state;
      this.showExcludeLocationDetails = false;
    },
    getRfpLineData(data) {
      const payload = {
        rfp_header_id: data.rfp_header_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRfpLineData", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.totalRows = resp.data.data.total_elements;
            this.rpfLineData = result;
            for (let i of this.rpfLineData) {
              i.bid_id = 0;
            }

            // this.getRfpItemData();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    postRfpItemData() {
      if (this.rfpHdrDetails.location) {
        let count = 0;
        for (let i of this.rpfLineData) {
          if (!i.include_item) {
            count++;
          }
        }
        if (count > 0) {
          this.openSaveModal = true;
        } else {
          if (!this.rfpHdrDetails.location) {
            alert("Please select location first!");
          } else {
            this.saveData();
            this.getLocationDetails();
          }
        }
      } else {
        this.showReviewModal = true;
      }
    },
    getRfpItemData() {
      const payload = {
        bid_hdr_id: this.bidHeaderId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRfpItemData", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            this.rfpHdrDetails.version = resp.data.data.version;
            this.selectedVersion = {
              value: this.bidHeaderId,
              text: this.rfpHdrDetails.version,
            };
            let result = resp.data.data.rfp_vendor_bid_dtls;
            this.rfpHdrDetails.bidStatus = resp.data.data.status;
            if (this.rfpHdrDetails.bidStatus === "SUBMIT") {
              this.editMode = false;
            }
            setTimeout(() => {
              this.editMode = JSON.parse(JSON.stringify(this.editMode));
            }, 0);
            this.totalRows = resp.data.data.total_elements;
            for (let i = 0; i < this.rpfLineData.length; i++) {
              this.rpfLineData[i].unit_price = null;
              this.rpfLineData[i].remarks = null;
              this.rpfLineData[i].bid_id = 0;
              for (let j = 0; j < result.length; j++) {
                if (
                  this.rpfLineData[i].rfp_item_id === result[j].rfp_item_id &&
                  result[j].location_id === this.locationId &&
                  result[j].exc_item === false
                ) {
                  this.rpfLineData[i].unit_price = result[j].unit_price;
                  this.rpfLineData[i].remarks = result[j].remarks;
                  this.rpfLineData[i].bid_id = result[j].bid_id
                    ? result[j].bid_id
                    : 0;
                  this.rpfLineData[i].include_item = true;
                } else if (
                  this.rpfLineData[i].rfp_item_id === result[j].rfp_item_id &&
                  result[j].location_id === this.locationId &&
                  result[j].exc_item === true
                ) {
                  this.rpfLineData[i].unit_price = null;
                  this.rpfLineData[i].remarks = null;
                  this.rpfLineData[i].bid_id = 0;
                  this.rpfLineData[i].include_item = false;
                }
              }
            }
            setTimeout(() => {
              this.rpfLineData = JSON.parse(JSON.stringify(this.rpfLineData));
            }, 2000);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getItemAttributeLineData() {
      const payload = {
        itemId: this.priceModalHeaderData.rfp_item_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRfpItemAttributeLineData", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.attributeData = result;
            this.getItemAttributeData();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    postItemAttributeData() {
      let details = [];
      let count = 0;
      for (let i of this.attributeData) {
        if (i.remarks === null) {
          count++;
        } else {
          details.push({
            bid_attribute_id: i.bid_attribute_id ? i.bid_attribute_id : 0,
            item_attribute_id: i.item_attribute_id,
            rating: i.rating,
            remarks: i.remarks,
          });
        }
      }
      if (count > 0) {
        alert("Remarks mandatory!");
      } else {
        const payload = {
          bid_id: this.bidId,
          rfp_item_id: this.priceModalHeaderData.rfp_item_id,
          rfp_vendor_attribute_bid_dtls: details,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/postRfpItemAttributeData", payload)
          .then((resp) => {
            this.loader = false;
            if (resp.status === 200) {
              this.responseMsg = resp.data.message;
              this.makeToast(this.responseMsg, "success");
              // this.getItemAttributeData();
              this.getItemAttributeLineData();
            } else {
              this.responseMsg = resp.data.message;
              this.makeToast(this.responseMsg, "danger");
            }
          })
          .catch((err) => {
            this.loader = false;
            this.makeToast(err.message, "danger");
          });
        this.showAttributeDetails = false;
      }
    },
    getItemAttributeData() {
      for (let i of this.attributeData) {
        i.remarks = null;
      }
      const payload = {
        bidId: this.bidId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRfpItemAttributeData", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            for (let i = 0; i < this.attributeData.length; i++) {
              this.attributeData[i].rating = result[i].rating;
              this.attributeData[i].remarks = result[i].remarks;
              this.attributeData[i].bid_attribute_id =
                result[i].bid_attribute_id;
            }
          }
          setTimeout(() => {
            this.attributeData = JSON.parse(JSON.stringify(this.attributeData));
          }, 0);
        })
        .catch(() => {
          this.loader = false;
        });
    },
    hideAttributepDetails() {
      this.showAttributeDetails = false;
    },
    showHideLocations(flag) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        rfp_header_id: this.rfpHdrDetails.rfp_header_id,
        rfp_vendor_id: this.rfpHdrDetails.rfp_vendor_id,
        negotiation_hdr_id: this.rfpHdrDetails.negotiation_hdr_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRfpLocationDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.copyLocationData = result;
            this.copyLocationData = this.copyLocationData.filter(
              (el) =>
                el.location_id !== this.locationId && el.exc_loc_id === null
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
      this.showHideLocationsModal = flag;
      this.selectAllCheckBox = false;
    },
    selectBoxChecked(flag, index, item) {
      this.copyLocationData[index].selectBox = flag;
      if (flag === true) {
        if (item.cost_centre !== null) {
          this.selectedLocation.push(item.cost_centre);
        } else if (item.cost_centre === null && item.state !== null) {
          this.selectedLocation.push(item.state);
        } else if (
          item.state === null &&
          item.state === null &&
          item.region !== null
        ) {
          this.selectedLocation.push(item.region);
        } else if (
          item.state === null &&
          item.state === null &&
          item.region === null
        ) {
          this.selectedLocation.push(item.country);
        }
        this.selectedLocationId.push(item.location_id);
        if (this.selectedLocation.length === this.copyLocationData.length) {
          this.selectAllCheckBox = true;
        }
      } else {
        this.selectAllCheckBox = false;
        if (item.cost_centre !== null) {
          this.selectedLocation = this.selectedLocation.filter(
            (el) => el !== item.cost_centre
          );
        } else if (item.cost_centre === null && item.state !== null) {
          this.selectedLocation = this.selectedLocation.filter(
            (el) => el !== item.state
          );
        } else if (
          item.cost_centre === null &&
          item.state === null &&
          item.region !== null
        ) {
          this.selectedLocation = this.selectedLocation.filter(
            (el) => el !== item.region
          );
        } else if (
          item.cost_centre === null &&
          item.state === null &&
          item.region === null
        ) {
          this.selectedLocation = this.selectedLocation.filter(
            (el) => el !== item.country
          );
        }
        this.selectedLocationId = this.selectedLocationId.filter(
          (el) => el !== item.location_id
        );
      }
    },
    // selectVersionChecked(flag, index, item) {
    //   this.copyVersionData[index].selectVersion = flag;
    //   if (flag === true) {
    //     this.selectedVersion.push({});
    //   } else {
    //     this.selectedLocation = this.selectedLocation.filter(
    //       (el) => el.location_id !== item.location_id
    //     );
    //     this.selectedLocationId = this.selectedLocationId.filter(
    //       (el) => el.location_id !== item.location_id
    //     );
    //   }
    // },
    selectAllExcludedItems(flag) {
      if (flag) {
        for (let i of this.rpfLineData) {
          i.exclude_item = true;
        }
      } else {
        for (let i of this.rpfLineData) {
          i.exclude_item = false;
        }
      }
      setTimeout(() => {
        this.rpfLineData = JSON.parse(JSON.stringify(this.rpfLineData));
      }, 0);
    },
    selectAllBoxChecked(flag) {
      this.selectedLocation = [];
      this.selectedLocationId = [];
      if (flag) {
        for (let i of this.copyLocationData) {
          i.selectBox = true;
          if (i.cost_centre !== null) {
            this.selectedLocation.push(i.cost_centre);
          } else if (i.cost_centre === null && i.state !== null) {
            this.selectedLocation.push(i.state);
          } else if (
            i.cost_centre === null &&
            i.state === null &&
            i.region !== null
          ) {
            this.selectedLocation.push(i.region);
          } else if (
            i.cost_centre === null &&
            i.state === null &&
            i.region === null
          ) {
            this.selectedLocation.push(i.country);
          }
          this.selectedLocationId.push(i.location_id);
        }
      } else {
        for (let i of this.copyLocationData) {
          i.selectBox = false;
        }
        this.selectedLocation = [];
        this.selectedLocationId = [];
      }
      setTimeout(() => {
        this.copyLocationData = JSON.parse(
          JSON.stringify(this.copyLocationData)
        );
      }, 0);
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: "Alert",
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-center",
        appendToast: true,
        toastClass: "custom-toast",
      });
    },
    saveCopyBid() {
      let copybiddetails = [];
      for (let i of this.selectedLocationId) {
        copybiddetails.push({
          to_location_id: i,
        });
      }
      const payload = {
        rfp_header_id: this.rfpHdrDetails.rfp_header_id,
        from_bid_hdr_id: this.selectedVersion.value,
        bid_hdr_id: this.bidHeaderId,
        from_location_id: this.locationId,
        rfp_copy_bid_dtls: copybiddetails,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/postCopyBid", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            this.editMode = false;
            this.responseMsg = "Bid Copied Successfully for other locations";
            this.makeToast(this.responseMsg, "success");
            this.showCopyLocation = false;
            this.showReviewModal = false;
            this.showHideLocationsModal = false;
          } else {
            this.responseMsg = resp.data.message;
            this.makeToast(this.responseMsg, "danger");
          }
        })
        .catch((err) => {
          this.loader = false;
          this.makeToast(err.message, "danger");
        });
    },
    openReviewModal() {
      this.reviewAndSubmitFlag = true;
      if (this.locationSelected === null) {
        this.showReviewModal = true;
      } else {
        this.postRfpItemData();
      }
    },
    showHideReviewModal(flag) {
      this.reviewAndSubmitFlag = flag;
      this.showReviewModal = flag;
    },
    alterEditBidMode() {
      this.editMode = !this.editMode;
    },
    getOpenKmAuthenticate(type) {
      if (type === "attachment") {
        (this.documentId = this.rfpHdrDetails.rfp_header_id),
          (this.subMenuId = 0);
      } else {
        const menuitem = JSON.parse(sessionStorage.getItem("RFPTNC"));
        this.subMenuId = menuitem.sub_menu_id;
        this.documentId = this.termId;
      }
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.showHideDocumentModal(true);
          } else {
            alert(appStrings.DEPENDENTVSETMSG.DEFAULTMSG);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideDocumentModal(flag) {
      this.showDocumentModal = flag;
    },
    openTermsCondition() {
      if (this.termId === null) {
        this.termsFlag = true;
      } else {
        const payload = {
          term_id: this.termId,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/getTermId", payload)
          .then((response) => {
            this.loader = false;
            if (response.status === 200) {
              this.summary = response.data.data.rfp_t_and_c;
              setTimeout(() => {
                this.summary = JSON.parse(JSON.stringify(this.summary));
              }, 500);
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
      this.showOpenTermsCondition = true;
    },
    closeTermsCondition(flag) {
      this.showOpenTermsCondition = flag;
    },
    qtyTwoDecimalValue(index) {
      let unitPrice = this.priceData[index].unit_price.toString().split(".");
      if (unitPrice.length > 1 && unitPrice[1].length > 2) {
        this.priceData[index].unit_price = parseFloat(
          this.priceData[index].unit_price
        ).toFixed(2);
        alert("Only Two Decimal Value Allowed.");
      }
    },
    checkUnitPriceApplicable(unitPrice, index) {
      if (unitPrice && unitPrice > 0) {
        this.priceData[index].include_item = true;
      } else {
        this.priceData[index].include_item = false;
      }
    },
    closeSaveModal(flag) {
      this.openSaveModal = flag;
    },
    closeModal() {
      this.openSaveModal = false;
    },
    saveData() {
      let vendorbiddetails = [];
      for (let i of this.rpfLineData) {
        vendorbiddetails.push({
          bid_id: i.bid_id,
          rfp_item_id: i.rfp_item_id,
          unit_price: i.unit_price,
          remarks: i.remarks,
          location_id: this.locationId,
          exc_item: !i.include_item,
        });
      }
      const payload = {
        bid_hdr_id: this.bidHeaderId,
        negotiation_hdr_id: this.rowDetails.negotiation_hdr_id,
        negotiation_vendor_id: this.rowDetails.negotiation_vendor_id,
        rfp_header_id: this.rowDetails.rfp_header_id,
        version: 0,
        status: "DRAFT",
        rfp_vendor_bid_dtls: vendorbiddetails,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/postRfpItemData", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            this.rfpHdrDetails.bidStatus = resp.data.data.status;
            if (this.rfpHdrDetails.bidStatus === "SUBMIT") {
              this.editMode = false;
            }
            const result = resp.data.data;
            this.bidHeaderId = result.bid_hdr_id;
            this.responseMsg = resp.data.message;
            this.makeToast(this.responseMsg, "success");
            // this.eventBus.$emit("bidHeaderId", this.bidHeaderId);
            this.getRfpItemData();
            if (this.reviewAndSubmitFlag) {
              this.reviewAndSubmitFlag = false;
              this.showReviewModal = true;
            }
          } else {
            this.responseMsg = response.response.data.message;
            this.makeToast(this.responseMsg, "danger");
          }
        })
        .catch((err) => {
          this.loader = false;
          this.makeToast(err.message, "danger");
        });
      this.openSaveModal = false;
    },
    submitBtn() {
      this.totalUploadedDocumentRows = null;
      this.termsFlag = true;
      this.showOpenTermsCondition = false;
    },
    showHideVersion(flag) {
      const payload = {
        rfp_header_id: this.rfpHdrDetails.rfp_header_id,
        rfp_vendor_id: this.rfpHdrDetails.rfp_vendor_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRfpVersion", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.copyVersionData = result;
            (this.negotiation_seq = result.negotiation_seq),
              (this.version = result.version),
              (this.bid_hdr_id = result.bid_hdr_id);
          }
        })
        .catch(() => {
          this.loader = false;
        });

      this.showHideVersionModal = flag;
    },
    versionSelected(item) {
      this.selectedVersion = {
        value: item.bid_hdr_id,
        text: item.version,
      };

      this.showHideVersionModal = false;
    },
    clearValues(value) {
      if (value === "selectedVersion") {
        if (this.selectedVersion.value === this.bidHeaderId) {
          alert("You cannot clear the current version");
        } else {
          this.selectedVersion = {
            value: null,
            text: null,
          };
        }
      }
    },
    updateEditorData(data) {
      this.summary = data;
    },
    showHidePriceModal(flag, item) {
      if (item) {
        this.priceModalHeaderData = item;
        this.getLocationData();
        // this.getBidData();
      }
      this.showPriceModal = flag;
    },
    getLocationData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        rfp_header_id: this.rfpHdrDetails.rfp_header_id,
        rfp_vendor_id: this.rfpHdrDetails.rfp_vendor_id,
        negotiation_hdr_id: this.rfpHdrDetails.negotiation_hdr_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRfpLocationDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.priceData = result;
            this.bidHeaderId = result[0].bid_hdr_id ? result[0].bid_hdr_id : 0;
            this.totalLocationDetailsRows = resp.data.data.total_elements;
            this.getBidData();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBidData() {
      const payload = {
        bid_hdr_id: this.bidHeaderId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRfpItemData", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            this.rfpHdrDetails.version = resp.data.data.version;
            this.selectedVersion = {
              value: this.bidHeaderId,
              text: this.rfpHdrDetails.version,
            };
            let result = resp.data.data.rfp_vendor_bid_dtls;

            this.rfpHdrDetails.bidStatus = resp.data.data.status;
            if (this.rfpHdrDetails.bidStatus === "SUBMIT") {
              this.editMode = false;
            }
            setTimeout(() => {
              this.editMode = JSON.parse(JSON.stringify(this.editMode));
            }, 0);
            this.totalRows = resp.data.data.total_elements;
            for (let i = 0; i < this.priceData.length; i++) {
              this.priceData[i].unit_price = null;
              this.priceData[i].remarks = null;
              this.priceData[i].bid_id = 0;
              for (let j = 0; j < result.length; j++) {
                if (
                  this.priceModalHeaderData.rfp_item_id ===
                    result[j].rfp_item_id &&
                  result[j].location_id === this.priceData[i].location_id &&
                  result[j].exc_item === false
                ) {
                  this.priceData[i].unit_price = result[j].unit_price;
                  this.priceData[i].remarks = result[j].remarks;
                  this.priceData[i].bid_id = result[j].bid_id
                    ? result[j].bid_id
                    : 0;
                  this.priceData[i].include_item = true;
                } else if (
                  this.priceModalHeaderData.rfp_item_id ===
                    result[j].rfp_item_id &&
                  result[j].location_id === this.priceData[i].location_id &&
                  result[j].exc_item === true
                ) {
                  this.priceData[i].unit_price = null;
                  this.priceData[i].remarks = null;
                  this.priceData[i].bid_id = 0;
                  this.priceData[i].include_item = false;
                }
              }
            }
            setTimeout(() => {
              this.priceData = JSON.parse(JSON.stringify(this.priceData));
            }, 500);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveRfpData() {
      let vendorbiddetails = [];
      for (let i of this.priceData) {
        vendorbiddetails.push({
          bid_id:
            this.rfpHdrDetails.bidStatus === "SUBMIT" ||
            this.rfpHdrDetails.bidStatus === null ||
            this.rfpHdrDetails.bidStatus === ""
              ? 0
              : i.bid_id,
          rfp_item_id: this.priceModalHeaderData.rfp_item_id,
          unit_price: i.unit_price,
          remarks: i.remarks,
          location_id: i.location_id,
          exc_item: !i.include_item,
        });
      }
      const payload = {
        bid_hdr_id:
          this.rfpHdrDetails.bidStatus === "SUBMIT" ? 0 : this.bidHeaderId,
        negotiation_hdr_id: this.rowDetails.negotiation_hdr_id,
        negotiation_vendor_id: this.rowDetails.negotiation_vendor_id,
        rfp_header_id: this.rowDetails.rfp_header_id,
        version: 0,
        status: "DRAFT",
        rfp_vendor_bid_dtls: vendorbiddetails,
      };
      this.editMode = false;
      this.$store
        .dispatch("vendor/postRfpItemData", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            this.rfpHdrDetails.bidStatus = resp.data.data.status;
            if (this.rfpHdrDetails.bidStatus === "SUBMIT") {
              this.editMode = false;
            }
            const result = resp.data.data;
            this.bidHeaderId = result.bid_hdr_id;
            this.getLocationData();
            this.responseMsg = resp.data.message;
            this.makeToast(this.responseMsg, "success");
            this.editMode = true;
            // this.eventBus.$emit("bidHeaderId", this.bidHeaderId);
            // this.getRfpItemData();
            // setTimeout(() => {
            //   alert('Please save Payment Terms Remarks')
            // }, 1000)
            if (this.reviewAndSubmitFlag) {
              this.reviewAndSubmitFlag = false;
              this.showReviewModal = true;
            }
          } else {
            this.responseMsg = response.response.data.message;
            this.makeToast(this.responseMsg, "danger");
            this.editMode = true;
          }
        })
        .catch((err) => {
          this.loader = false;
          this.makeToast(err.message, "danger");
        });
      this.openSaveModal = false;
    },
    alterEditMode() {
      this.editMode = true;
    },
    copyUnitPriceToAll() {
      let count = 0;
      let unitPrice = 0;
      let remarks = null;
      let unitPriceCount = 0;
      for (let i of this.priceData) {
        if (i.unit_price > 0) {
          unitPriceCount = unitPriceCount + 1;
        }
      }
      if (unitPriceCount === 0) {
        alert("Please enter Unit Price first");
      } else {
        for (let i of this.priceData) {
          if (i.unit_price && (i.unit_price !== null || i.unit_price !== "")) {
            count++;
            unitPrice = i.unit_price;
            remarks = i.remarks;
          }
        }
        if (count === 1) {
          for (let i of this.priceData) {
            i.unit_price = unitPrice;
            i.remarks = remarks;
          }
          setTimeout(() => {
            this.priceData = JSON.parse(JSON.stringify(this.priceData));
          }, 0);
        } else {
          alert(
            "You have entered multiple prices for this item. Copying prices is not allowed."
          );
        }
      }
    },
    copyItemAttributesData() {
      let bidCopyAttributeDetails = [];
      for (let i of this.priceData) {
        bidCopyAttributeDetails.push({
          to_bid_id: i.bid_id,
        });
      }
      const payload = {
        rfp_item_id: this.priceModalHeaderData.rfp_item_id,
        from_bid_id: this.bidId,
        rfp_bid_copy_atr_dtls: bidCopyAttributeDetails,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/copyItemAttributeData", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            this.responseMsg = resp.data.data;
            this.makeToast(this.responseMsg, "success");
          } else {
            this.responseMsg = resp.data.data;
            this.makeToast(this.responseMsg, "danger");
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthenticateUploadDocument() {
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.showHideUploadDocumentModal(true);
          } else {
            alert(appStrings.DEPENDENTVSETMSG.DEFAULTMSG);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideUploadDocumentModal(flag) {
      this.showUploadDocumentModal = flag;
    },
    setMenuSubmenuId() {
      const menuRfpHeader = JSON.parse(sessionStorage.getItem("rfpHeader"));
      this.menuSubmenuRfpHeader = {
        menuId: menuRfpHeader.menu_id,
        submenuId: menuRfpHeader.sub_menu_id,
      };
      const menuBidHeader = JSON.parse(sessionStorage.getItem("rfpBidHdr"));
      this.menuSubmenuBidHeader = {
        menuId: menuBidHeader.menu_id,
        submenuId: menuBidHeader.sub_menu_id,
      };
      const menuItemTermsAndCondition = JSON.parse(
        sessionStorage.getItem("rfpTermsAndConditions")
      );
      this.menuSubmenuTermsAndCondition = {
        menuId: menuItemTermsAndCondition.menu_id,
        submenuId: menuItemTermsAndCondition.sub_menu_id,
      };
    },
  },
  beforeDestroy() {
    // this.eventBus.$off("bidHeaderId");
  },
};
</script>

<style>
@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.icon-animate {
  margin-top: -20px;
  font-size: 20px;
  animation: moveUpDown 1s ease-in-out infinite;
}

.icon {
  margin-top: -20px;
  font-size: 20px;
}
.icon {
  cursor: pointer;
}
.ck-editor__editable[role="textbox"] {
  pointer-events: none;
}
.custom-toast {
  padding: 20px;
}
</style>
