<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="90"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <b-row>
      <!-- <div class="overlay" v-if="loader">
      <b-spinner class="loader" variant="primary" label="Spinning"></b-spinner>
    </div> -->
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-60"
        >
          <b-alert
            :variant="isSuccess ? 'info' : 'danger'"
            :show="showAlert"
            dismissible
            fade
            @dismissed="showAlert = false"
            >{{ respMessage }}
          </b-alert>
        </div>
      </div>
      <slot></slot>
      <b-row
        v-if="
          approval_status !== 'APPROVED' &&
            approval_status !== 'INPROCESS' &&
            formName !== 'rfp' &&
            formName !== 'rfpItems' &&
            formName !== 'rfpBoq' &&
            formName !== 'rfpQuotationView' &&
            formName !== 'rfpTnc'
        "
      >
        <b-col>
          <input
            type="file"
            ref="file"
            id="FileUpload1"
            style="display: none"
            @change="selectFile()"
          />
          <md-button
            class="md-info"
            style="margin-left: 15px"
            @click="browseFile()"
            >Add Files..</md-button
          >
          <md-button
            class="md-warning"
            style="margin-left: 5px"
            @click="uploadFile()"
            >Start Upload</md-button
          >
          <!-- <md-button class="md-danger" style="margin-left: 5px"
          >Cancel Upload</md-button
        > -->
        </b-col>
      </b-row>
      <div class="md-layout-item" style="margin-top: 5px !important">
        <b-table
          striped
          hover
          outlined
          :items="uploadDocList"
          :fields="uploadDocFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
        >
          <template #cell(file_name)="data">
            <a
              target="_blank"
              :href="
                downloadDocUrl +
                  '/download/' +
                  data.item.docDetailId +
                  '?token=' +
                  openKmAuth
              "
              v-if="data.item.docDetailId !== 0"
            >
              {{ data.item.file_name }}</a
            >
            <span v-else>{{ data.item.file_name }}</span>
          </template>
          <template #cell(preview)="data">
            <a
              href="#"
              style="cursor:pointer;"
              @click="
                getOpenKmAuthenticatePreview(
                  data.item.docDetailId,
                  data.item.file_name,
                  data.index,
                  uploadDocList
                )
              "
              ><span v-if="data.item.docDetailId" class="material-icons">
                preview
              </span></a
            >
          </template>
          <template #cell(leName)="data">
            <b-form-input
              type="text"
              v-model="data.item.leName"
              readonly
            ></b-form-input>
          </template>
          <template #cell(categoryName)="data">
            <b-form-input
              type="text"
              v-model="data.item.categoryName"
              readonly
            ></b-form-input>
          </template>
          <template #cell(subcategoryName)="data">
            <b-form-input
              type="text"
              v-model="data.item.subcategoryName"
              @click="openValueSetModal('OKM_SUB_CAT_DOC', data.index)"
              readonly
            ></b-form-input>
          </template>
          <template #cell(comments)="data">
            <b-form-input
              type="text"
              :disabled="data.item.docDetailId !== 0"
              v-model="data.item.comments"
            ></b-form-input>
          </template>
          <template
            #cell(delete)="data"
            v-if="
              formName !== 'rfp' &&
                formName !== 'rfpItems' &&
                formName !== 'rfpBoq'
            "
          >
            <div
              v-if="data.item.docDetailId"
              @click="deleteRow(data.index, data.item.docDetailId)"
            >
              <md-icon style="cursor:pointer;">delete</md-icon>
              <!-- <md-icon v-if="data.item.docDetailId">delete</md-icon> -->
            </div>
            <div v-else @click="deleteTempRow(data.index)">
              <md-icon style="cursor:pointer;">delete</md-icon>
            </div>
          </template>
        </b-table>
      </div>
      <b-pagination
      v-if="formName !== 'rfpBoq'&& formName !== 'rfpItems'"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      ></b-pagination>
      <div
        v-if="formName == 'invoice_entry'"
        style="text-align:right; margin-top:20%"
      >
        <span style="color:red;">
          <b>*Maximum file size 15 MB</b><br />
          <b>*only pdf, jpg, jpeg, png, zip, excel file allowed</b>
        </span>
      </div>
      <div
        v-if="formName === 'rfpQuotation'"
        style="text-align:right; margin-top:20%"
      >
        <span style="color:red;">
          <b>*Maximum file size 20 MB</b><br />
          <b>*only pdf, jpg, jpeg, png, zip, excel file allowed</b>
        </span>
      </div>
      <b-modal
        v-model="showOpenUploadDocModal"
        no-close-on-esc
        no-close-on-backdrop
        hide-footer
        size="xl"
        @hidden="showHideUploadDocModal(false)"
        title="Preview"
      >
        <previewDoc
          :authToken="authTokenPreview"
          :docDtlId="docDtlId"
          :uploadedFileName="uploadedFileName"
          :currentPageIndex="currentPageIndex"
          :data="dynamicData"
        ></previewDoc>
      </b-modal>
      <valueSetModal
        v-if="showValueSetModal"
        @selectedvalueSet="selectedvalueSet"
        @closeValueSetModal="closeValueSetModal"
      ></valueSetModal>
    </b-row>
  </div>
</template>
<script>
import URL_UTILITY from "../utility/url.utility";
import commonHelper from "../utility/common.helper.utility";
import appStrings from "../utility/string.utility";
import previewDoc from "../pages/Dashboard/PreviewDoc/PreviewDoc.vue";

export default {
  components: { previewDoc },
  name: "uploadDocument",
  props: [
    "wfHdrId",
    "openKmAuth",
    "selectedInvoiceData",
    "approval_status",
    "propMenuId",
    "PropSubMenuId",
    "documentFlag",
    "formName",
  ],
  watch: {
    currentPage: function() {
      this.getUploadedDocDtl();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUploadedDocDtl();
    },
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      progressBarMaxValue: 100,
      invoiceNumber: null,
      prgressbarValue: 0,
      downloadDocUrl: URL_UTILITY.getDocumentDownloadUrl,
      selectedFilesArr: [],
      loader: false,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      uploadDocList: [],
      selectedObjectData: {
        le_id: null,
        le_name: null,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null,
      },
      uploadDocFields: [
        {
          key: "file_name",
        },
        {
          key: "file_size",
        },
        {
          key: "preview",
        },
        {
          key: "delete",
        },
      ],
      menuId: null,
      subMenuId: null,
      showValueSetModal: false,
      vsetCode: null,
      index: null,
      showOpenUploadDocModal: false,
      authToken: null,
      uploadedFileName: null,
      docDtlId: null,
      openKmAuthPreview: null,
      authTokenPreview: null,
      currentPageIndex: null,
      dynamicData: null,
      userId: null,
    };
  },
  mounted() {
    this.userId = JSON.parse(sessionStorage.getItem("userId"));
    if (this.documentFlag) {
      this.uploadDocFields.unshift({
        key: "subcategoryName",
        label: "Document",
      });
    }
    if (this.approval_status === "INPROCESS") {
      this.uploadDocFields.pop();
    }
    if (this.selectedInvoiceData) {
      this.selectedObjectData = this.selectedInvoiceData;
    }
    this.menuId = this.propMenuId;
    this.subMenuId = this.PropSubMenuId;
    this.getUploadedDocDtl();
    // this.invoiceNumber = this.selectedInvoiceData.invoice_num;
    if (
      this.formName === "rfp" ||
      this.formName === "rfpItems" ||
      this.formName === "rfpQuotation"
    ) {
      this.uploadDocFields = [
        {
          key: "file_name",
        },
        {
          key: "preview",
        },
        {
          key: "delete",
        },
      ];
    } else if(this.formName === "rfpBoq" || this.formName === "rfpTnc") {
      this.uploadDocFields = [
        {
          key: "file_name",
        },
        {
          key: "preview",
        },
      ];
    }
  },
  methods: {
    getOpenKmAuthenticatePreview(docDtlId, fileName, index, data) {
      const filterDynamicData = data.filter((a) => a.file_name);
      const getFileName = data[index].file_name;
      for (let i = 0; i < filterDynamicData.length; i++) {
        if (filterDynamicData[i].file_name == getFileName) {
          this.currentPageIndex = i + 1;
          break;
        }
      }
      const tempData = filterDynamicData.map((elem) => {
        return {
          original_file_name: elem.file_name,
          document_det_id: elem.docDetailId,
        };
      });
      this.dynamicData = tempData;
      const extension = commonHelper.getFileExtension(fileName);
      if (extension === "zip") {
        alert("Preview Not Supported this file.");
      } else {
        this.$store.dispatch("shared/setLoader", true);
        this.$store
          .dispatch("uploadDocument/getOpenKmAuthenticate")
          .then((response) => {
            this.$store.dispatch("shared/setLoader", false);
            if (response.status === 200) {
              this.openKmAuthPreview = response.data.data.token;
              if (this.openKmAuthPreview) {
                this.showHideUploadDocModal(
                  true,
                  docDtlId,
                  this.openKmAuthPreview,
                  fileName
                );
              }
            }
          })
          .catch(() => {
            this.$store.dispatch("shared/setLoader", false);
          });
      }
    },
    showHideUploadDocModal(flag, docDtlId, authTokenPreview, fileName) {
      if (flag) {
        this.fileExtension = commonHelper.getFileExtension(fileName);
      }
      if (this.fileExtension === "xlsx") {
        alert("Not Allowed");
      } else {
        this.showOpenUploadDocModal = flag;
        this.docDtlId = docDtlId;
        this.authTokenPreview = authTokenPreview;
        this.uploadedFileName = fileName;
      }
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: this.menuId,
        sub_menu_id: this.subMenuId,
        record_id: this.wfHdrId,
        user_id: this.userId,
      };
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map((data) => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.selectedObjectData
                    ? this.selectedObjectData.le_id
                    : null,
                  leName: this.selectedObjectData
                    ? this.selectedObjectData.le_name
                    : null,
                  categoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat_vset
                    : null,
                  categoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat
                    : null,
                  subcategoryCode: data.doc_sub_category_vset
                    ? data.doc_sub_category_vset
                    : this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat_vset
                    : null,
                  subcategoryName: data.doc_sub_category_meaning
                    ? data.doc_sub_category_meaning
                    : this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat
                    : null,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: this.formatBytes(data.file_size),
                  action: null,
                  docDetailId: data.doc_detail_id,
                };
              });
              this.uploadDocList = docList;
              if (
                this.$route.name === "RFP" &&
                this.uploadDocList.length > 0 &&
                this.formName === "rfp"
              ) {
                this.getOpenKmAuthenticatePreview(
                  this.uploadDocList[0].docDetailId,
                  this.uploadDocList[0].file_name,
                  0,
                  this.uploadDocList
                );
              }
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
      this.eventBus.$emit("totalUploadedDocumentRows", this.totalRows);
    },
    browseFile() {
      this.$refs.file.click();
    },
    selectFile() {
      if (this.$refs.file.files[0].size > 15728640 && this.formName !== 'rfpQuotation') {
        alert(
          this.$refs.file.files[0].name + " file should be less than 15 MB."
        );
      } else if (this.$refs.file.files[0].size > 20971520 && this.formName === 'rfpQuotation') {
        alert(
          this.$refs.file.files[0].name + " file should be less than 20 MB."
        );
      } else {
        this.selectedFilesArr.push(this.$refs.file.files);
        this.uploadDocList.push({
          fileRef: this.$refs.file.files,
          file_name: this.$refs.file.files[0].name,
          // leId: this.selectedObjectData.le_id,
          // leName: this.selectedObjectData.le_name,
          categoryCode: this.selectedObjectData.nfa_cat_vset,
          categoryName: this.selectedObjectData.nfa_cat,
          subcategoryCode: this.selectedObjectData.nfa_sub_cat_vset,
          subcategoryName: this.selectedObjectData.nfa_sub_cat,
          comments: "",
          version: 0,
          file_size: this.formatBytes(this.$refs.file.files[0].size),
          action: null,
          docDetailId: 0,
        });
      }
    },
    uploadFile() {
      const filterDocData = this.uploadDocList.filter(
        (data) => data.docDetailId === 0
      );
      let checkSubCategory = true;
      if (this.documentFlag) {
        checkSubCategory = this.uploadDocList.every(
          (data) => data.subcategoryCode !== null
        );
      }
      if (checkSubCategory) {
        if (filterDocData.length > 0) {
          filterDocData.forEach((data) => {
            let formData = new FormData();
            formData.append("file", data.fileRef.item(0));
            formData.append("token", this.openKmAuth);
            // formData.append(
            //   "category",
            //   data.categoryCode ? data.categoryCode : "DOCUMENT"
            // );
            // formData.append(
            //   "sub_category",
            //   data.subcategoryCode ? data.subcategoryCode : "TAN"
            // );
            // formData.append("comment", data.comments ? data.comments : "TAN");
            formData.append("doc_detail_id", 0);
            formData.append("menu_id", this.menuId);
            formData.append("sub_menu_id", this.subMenuId);
            // formData.append("le_id", data.leId);
            formData.append("record_id", this.wfHdrId);
            this.loader = true;
            // this.$store.dispatch("shared/setLoader", true);
            this.$store
              .dispatch("uploadDocument/uploadFile", formData)
              .then((response) => {
                this.loader = false;
                // this.$store.dispatch("shared/setLoader", false);
                this.showAlert = true;
                if (response.status === 200) {
                  this.$emit("updateStatusFlag", true);
                  this.getUploadedDocDtl();
                  this.editMode = false;
                  this.isSuccess = true;
                  this.respMessage = response.data.message;
                } else {
                  this.isSuccess = false;
                  this.respMessage =
                    response.data.message || "Something went wrong";
                }
              })
              .catch(() => {
                this.loader = false;
                // this.$store.dispatch("shared/setLoader", false);
              });
          });
        }
      } else {
        alert("Please Select Document Name");
      }
    },
    deleteRow(index, docDetailId) {
      const promt = confirm("Are you sure, you want to delete this document.?");
      if (promt) {
        const payload = {
          docDetailId: docDetailId,
          token: this.openKmAuth,
        };
        this.loader = true;
        // this.$store.dispatch("shared/setLoader", true);
        this.$store
          .dispatch("vendor/deleteOpenKmDoc", payload)
          .then((response) => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
              this.uploadDocList.splice(index, 1);
            } else {
              this.isSuccess = false;
              this.respMessage = "Something went wrong";
            }
            // this.$store.dispatch("shared/setLoader", false);
          })
          .catch(() => {
            this.loader = false;
            this.isSuccess = false;
            this.respMessage = "Something went wrong";
            // this.$store.dispatch("shared/setLoader", false);
          });
      }
    },
    deleteTempRow(index) {
      this.uploadDocList.splice(index, 1);
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.index = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null,
        };
        this.eventBus.$emit("valueSetCode", vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.OKM_SUB_CAT_DOC) {
        this.uploadDocList[this.index].subcategoryCode = item.value_code;
        this.uploadDocList[this.index].subcategoryName = item.value_meaning;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
  },
  updated() {
    this.$emit("uploadedDocLists", this.uploadDocList);
  },
};
</script>
