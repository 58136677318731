<template>
  <div>
    <div style="height: 60vh; overflow-y: scroll;">
      <div class="chat-container" v-if="answersFromBuyer.length > 0">
        <template v-if="loading">
          <div v-for="n in 5" :key="n" class="chat-row">
            <div class="chat-bubble buyer-message">
              <b-skeleton width="450px" height="16px"></b-skeleton>
              <b-skeleton width="400px" height="16px" class="mt-2"></b-skeleton>
            </div>
            <div class="answer-box">
              <b-skeleton width="80%" height="40px"></b-skeleton>
            </div>
          </div>
        </template>
        <!-- <template v-else>
          <div
            v-for="(message, index) in answersFromBuyer"
            :key="index"
            class="chat-row"
          >
            <div
              class="chat-bubble"
              :class="
                message.created_by === userId
                  ? 'buyer-message'
                  : 'vendor-message'
              "
            >
              <div class="message-meta">
                <span class="sender">{{ message.vendor_name }}</span>
                <span class="time">{{ message.creation_date_time }}</span>
              </div>
              <p class="message-text">{{ message.question_text }}</p>
            </div>
            <div class="answer-chat-bubble" v-if="message.answer_text">
              <div class="message-meta">
                <span class="sender">{{ message.buyer_name }}</span>
                <span class="time">{{ message.response_date_time }}</span>
              </div>
              <p class="answer-text">{{ message.answer_text }}</p>
            </div>
          </div>
        </template> -->

        <template v-else>
          <div
            v-for="(message, index) in answersFromBuyer"
            :key="index"
            class="chat-row"
          >
            <div class="qa-container">
              <div class="chat-bubble vendor-message">
                <div class="message-meta">
                  <span class="sender" v-if="partyName === message.vendor_name">{{ message.vendor_name }}</span>
                  <span class="sender" v-if="partyName !== message.vendor_name">Unknown Vendor</span>
                  <span class="time">{{ message.creation_date_time }}</span>
                </div>
                <p class="message-text">{{ message.question_text }}</p>
              </div>
              <div class="answer-chat-bubble buyer-message">
                <div class="message-meta">
                  <span class="sender">{{ message.buyer_name }}</span>
                  <span class="time">{{ message.response_date_time }}</span>
                </div>
                <p class="answer-text">{{ message.answer_text }}</p>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="chat-container" v-if="answersFromBuyer.length === 0">
        No messages yet...
      </div>
    </div>
    <div style="box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);">
      <md-field>
        <md-textarea
          class="bold-text"
          style=""
          v-model="question"
          maxlength="500"
          placeholder="Enter your question here..."
        ></md-textarea>
        <b-icon
          icon="arrow-right-square-fill"
          font-scale="2"
          style="cursor: pointer; margin-top: 50px;"
          @click="postQuestion()"
        ></b-icon>
        <b-icon
          icon="arrow-clockwise"
          font-scale="2"
          style="cursor: pointer; margin-top: 50px;"
          @click="getQuestion()"
        ></b-icon>
      </md-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "RFPBOQQNA",
  props: ["rfpHdrId", "rfpVendorId", "negotiationHeaderId"],
  data() {
    return {
      question: null,
      rfpHeaderId: null,
      answersFromBuyer: [],
      userId: null,
      askQuestionModal: false,
      loading: false,
      partyName: null
    };
  },
  mounted() {
    this.userId = JSON.parse(sessionStorage.getItem("userId"));
    if (this.rfpHdrId) {
      this.rfpHeaderId = this.rfpHdrId;
      this.getQuestion();
      this.getName();
    }
  },
  methods: {
    postQuestion() {
      const payload = {
        rfp_question: 1,
        rfp_question_id: 0,
        rfp_sub_question_id: 0,
        rfp_header_id: this.rfpHeaderId,
        negotiation_hdr_id: this.negotiationHeaderId,
        rfp_vendor_id: this.rfpVendorId,
        buyer_code: null,
        question_text: this.question,
        answer_text: "null",
      };
      this.loading = true;
      this.$store
        .dispatch("vendor/postVendorQuestion", payload)
        .then((resp) => {
          this.loading = false;
          if (resp.status === 200) {
            this.askQuestionModal = false;
            this.question = null;
            this.getQuestion();
          } else {
            this.makeToast(resp.data.message, "danger");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getQuestion() {
      const payload = {
        rfp_header_id: this.rfpHeaderId,
        negotiation_hdr_id: this.negotiationHeaderId,
      };
      this.loading = true;
      this.$store
        .dispatch("vendor/getVendorQuestion", payload)
        .then((resp) => {
          this.loading = false;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.answersFromBuyer = result;
            for (let i of this.answersFromBuyer) {
              if (i.answer_text === null || i.answer_text === "") {
                i.answered = false;
              } else {
                i.answered = true;
              }
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openAskQuestionModal(flag) {
      this.question = null;
      this.askQuestionModal = flag;
    },
    getName() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.loader = true;
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDetails", payload)
        .then((resp) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.partyName = result.name;
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: "Alert",
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-center",
        appendToast: true,
        toastClass: "custom-toast",
      });
    },
  },
};
</script>

<style>
.qa-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}
.chat-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
  margin: 20px;
}

.chat-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.chat-bubble {
  max-width: 100%;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
}

.vendor-message {
  background-color: #f1f1f1;
  align-self: flex-start;
  max-width: 100%;
}

.buyer-message {
  background-color: #c8f7c5;
  align-self: flex-start;
  max-width: 100%;
}
.answer-chat-bubble {
  margin-left: 20px;
}

.message-meta {
  font-size: 12px;
  color: #666;
}

.sender {
  font-weight: bold;
  margin-right: 10px;
}

.time {
  font-size: 10px;
}
/* .answer-box {
  display: flex;
  align-items: center;
  width: 45%;
  max-width: 100%;
  gap: 10px;
  background-color: #b4fff5;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
} */

.answer-box:hover {
  transform: scale(1.02);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
}

.answer-input {
  flex-grow: 1;
  width: 100%;
  min-height: 35px;
  max-height: 65px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #a0dca0;
  border-radius: 8px;
  background-color: #fff;
  transition: border 0.3s ease-in-out;
}

.answer-input:focus {
  border-color: #4caf50;
  outline: none;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.5);
}

.send-btn {
  flex-shrink: 0;
  padding: 8px 14px;
  border: none;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.send-btn:hover {
  background: linear-gradient(135deg, #0056b3, #004080);
  transform: scale(1.05);
}

.send-btn:active {
  transform: scale(0.95);
}

.answer-chat-bubble {
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 100%;
  word-wrap: break-word;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 100%;
  gap: 10px;
  background-color: #ace9ac;
}
</style>
