<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="90"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <b-row>
      <div class="md-layout">
        <div
         v-if="!rfpBoqError"
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-60"
        >
          <b-alert
            :variant="isSuccess ? 'info' : 'danger'"
            :show="showAlert"
            dismissible
            fade
            @dismissed="showAlert = false"
            >{{ responseMsg }}
          </b-alert>
        </div>
        <div v-if="rfpBoqError" md="8" style="background-color: pink; line-height: 0.4; font-size: 12px; padding: 2px;">       
        <p class="mt-2" v-for="(error, index) in responseMsg.split('\n')" :key="index">
  {{ error }}
</p>
      </div>
      </div>
      <slot></slot>
      <b-row>
        <b-col>
          <input
            type="file"
            ref="file"
            id="FileUpload1"
            style="display: none"
            @change="selectFile()"
          />
          <md-button
            class="md-info"
            style="margin-left: 15px"
            @click="browseFile()"
            >Add Files..</md-button
          >
          <md-button
            class="md-warning"
            style="margin-left: 5px"
            @click="uploadFile()"
            >Start Upload</md-button
          >
        </b-col>
      </b-row>
      <div class="md-layout-item" style="margin-top: 5px !important">
        <b-table
          striped
          hover
          outlined
          :items="uploadExcelData"
          :fields="uploadExcelFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
        >
          <template #cell(excel_name)="data">
            <a
              href="#"
              @click="showExcelDetails(true, data.item, data.index)"
              >{{ data.item.excel_name }}</a
            >
          </template>
          <template #cell(delete)="data">
            <div
              @click="removeRow(data.index, data.item.s_no)"
              v-if="data.item.s_no > 0"
            >
              <md-button class="md-rose">Delete</md-button>
            </div>
          </template>
        </b-table>
      </div>
    </b-row>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      v-model="showExcelDetailsModal"
      @hidden="showExcelDetails(false)"
      title="Upload Excel Details"
    >
      <ExcelDetails
        :excelRowDtl="excelRowDtl"
        @closeExcelDetail="closeExcelDetail"
        :recordId="recordId"
        :formName="formName"
        :excelProcess="excelProcess"
        :menuSubmenuIdExcelUploader="menuSubmenuIdExcelUploader"
      ></ExcelDetails>
    </b-modal>
  </div>
</template>

<script>
import ExcelDetails from "./ExcelDetails.vue";
import { required } from "vuelidate/lib/validators";
import appStrings from "../utility/string.utility";
export default {
  name: "uploadExcel",
  props: [
    "formName",
    "propMenuId",
    "PropSubMenuId",
    "wfHdrId",
    "rfpHdrId",
    "negotiationVendorId",
    "rfpVendorId",
    "negotiationHeaderId",
  ],
  components: {
    ExcelDetails,
  },
  data() {
    return {
      loader: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      uploadExcelData: [],
      selectedObjectData: {
        le_id: null,
        le_name: null,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null,
      },
      uploadExcelFields: [
        {
          key: "s_no",
          label: "Serial No.",
        },
        {
          key: "excel_name",
        },
        {
          key: "sheet_name",
        },
        {
          key: "row_uploaded",
        },
        {
          key: "mapped_column",
        },
        {
          key: "updated_by",
        },
        {
          key: "updation_date",
        },
        {
          key: "delete",
        },
      ],
      menuId: null,
      subMenuId: null,
      selectedFilesArr: [],
      recordId: null,
      excelImportHdrId: null,
      showExcelDetailsModal: false,
      excelRowDtl: null,
      excelProcess: {
        value: null,
        text: null,
      },
      responseMsg: null,
      menuSubmenuIdExcelUploader: {
        menuId: null,
        submenuId: null,
      },
      menuSubmenuBidHeader: {
        menuId: null,
        submenuId: null,
      },
      uploadDocList: [],
      userId: null,
      rfpBoqError: false
    };
  },
  validations: {
    excelProcess: {
      value: { required },
      text: { required },
    },
  },
  mounted() {
    this.userId = JSON.parse(sessionStorage.getItem("userId"));
    this.setMenuSubmenuId();
    this.recordId = this.wfHdrId;
    this.menuSubmenuIdExcelUploader = {
      menuId: this.propMenuId,
      submenuId: this.PropSubMenuId,
    };
  },
  methods: {
    selectFile() {
      this.selectedFilesArr.push(this.$refs.file.files);
      this.saveFileToTempTable(this.$refs.file.files.item(0));
      this.uploadDocList.push({
        fileRef: this.$refs.file.files,
        file_name: this.$refs.file.files[0].name,
        // leId: this.selectedObjectData.le_id,
        // leName: this.selectedObjectData.le_name,
        categoryCode: this.selectedObjectData.nfa_cat_vset,
        categoryName: this.selectedObjectData.nfa_cat,
        subcategoryCode: this.selectedObjectData.nfa_sub_cat_vset,
        subcategoryName: this.selectedObjectData.nfa_sub_cat,
        comments: "",
        version: 0,
        file_size: this.formatBytes(this.$refs.file.files[0].size),
        action: null,
        docDetailId: 0,
      });
    },
    browseFile() {
      this.$refs.file.click();
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    uploadFile() {
      if (this.uploadExcelData.length === 0) {
        alert("Please add file");
      } else {
        if (this.formName === "rfpUploadExcel") {
          this.excelUploadRfp();
        }
      }
    },
    excelUploadRfp() {
      const payload = {
        excel_import_hdr_id: this.uploadExcelData[0].excel_improt_hdr_id,
        menu_id: this.propMenuId,
        sub_menu_id: this.PropSubMenuId,
        rfp_header_id: this.rfpHdrId,
        negotiation_vendor_id: this.negotiationVendorId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/addUploadRfpExcelList", payload)
        .then((response) => {
          this.loader = false;
          this.showAlert = true;
          setTimeout(() => {
            this.showAlert = false;
          }, 2000);
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.getBidHeaderId();
            this.getOpenKmAuthenticateToken();
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.rfpBoqError = true;
            this.responseMsg = response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          // this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    saveFileToTempTable(fileName) {
      let formData = new FormData();
      formData.append("file", fileName);
      formData.append("menu_id", this.propMenuId);
      formData.append("sub_menu_id", 0);
      if (this.rfpHdrId) {
        formData.append("record_id", this.rfpHdrId ? this.rfpHdrId : null);
      }
      this.loader = true;
      this.$store
        .dispatch("vendor/addUploadExcelList", formData)
        .then((response) => {
          this.loader = false;
          this.showAlert = true;
          setTimeout(() => {
            this.showAlert = false;
          }, 2000);
          if (response.status === 200) {
            const results = response.data.data;
            this.excelImportHdrId = results[0].excel_improt_hdr_id;
            if (this.formName === "NFA_APPROVAL") {
              this.uploadExcelData = results.splice(0, 1);
            } else {
              this.uploadExcelData = results;
            }
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    removeRow(index) {
      this.uploadExcelData.splice(index, 1);
    },
    showExcelDetails(flag, rowData, index) {
      this.showExcelDetailsModal = flag;
      this.excelRowDtl = rowData;
      this.sheetIndex = index;
    },
    closeExcelDetail(columns) {
      if (columns || columns === 0) {
        this.uploadExcelData[this.sheetIndex].mapped_column = columns;
      }
      this.showExcelDetails(false);
    },
    uploadExcelDocument() {
      const filterDocData = this.uploadDocList.filter(
        (data) => data.docDetailId === 0
      );
      if (filterDocData.length > 0) {
        filterDocData.forEach((data) => {
          let formData = new FormData();
          formData.append("file", data.fileRef.item(0));
          formData.append("token", this.openKmAuth);
          formData.append("doc_detail_id", 0);
          formData.append("menu_id", this.menuSubmenuBidHeader.menuId);
          formData.append("sub_menu_id", this.menuSubmenuBidHeader.submenuId);
          // formData.append("le_id", null);
          formData.append("record_id", this.recordId);
          this.loader = true;
          this.$store
            .dispatch("uploadDocument/uploadFile", formData)
            .then((response) => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                alert("Documnt Uploaded Successfully!");
              } else {
                alert("Something went wrong while uploading document");
              }
            })
            .catch(() => {
              this.loader = false;
            });
        });
      }
    },
    getOpenKmAuthenticateToken() {
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.getUploadedDocDtl();
          } else {
            alert(appStrings.DEPENDENTVSETMSG.DEFAULTMSG);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setMenuSubmenuId() {
      const menuBidHeader = JSON.parse(sessionStorage.getItem("rfpBidHdr"));
      this.menuSubmenuBidHeader = {
        menuId: menuBidHeader.menu_id,
        submenuId: menuBidHeader.sub_menu_id,
      };
    },
    getUploadedDocDtl() {
      const payload = {
        _page: 0,
        _limit: 1000,
        menu_id: this.menuSubmenuBidHeader.menuId,
        sub_menu_id: this.menuSubmenuBidHeader.submenuId,
        record_id: this.recordId,
        user_id: this.userId,
      };
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.docDetailId = response.data.data.page[0].doc_detail_id;
              this.deleteRow();
              setTimeout(() => {
                this.uploadExcelDocument();
              }, 2000);
            } else {
              this.uploadExcelDocument();
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
      this.eventBus.$emit("totalUploadedDocumentRows", this.totalRows);
    },
    deleteRow() {
      const payload = {
        docDetailId: this.docDetailId,
        token: this.openKmAuth,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/deleteOpenKmDoc", payload)
        .then((response) => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
            this.uploadDocList.splice(index, 1);
          } else {
            this.isSuccess = false;
            this.respMessage = "Something went wrong";
          }
        })
        .catch(() => {
          this.loader = false;
          this.isSuccess = false;
          this.respMessage = "Something went wrong";
        });
    },
    getBidHeaderId() {
      const payload = {
        _page: 0,
        _limit: 1000,
        rfp_header_id: this.rfpHdrId,
        rfp_vendor_id: this.rfpVendorId,
        negotiation_hdr_id: this.negotiationHeaderId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRfpLocationDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.bidHeaderId = result[0].bid_hdr_id ? result[0].bid_hdr_id : 0;
            this.recordId = this.bidHeaderId;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: "Alert",
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-center",
        appendToast: true,
        toastClass: "custom-toast",
      });
    },
  },
};
</script>
