<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="90"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div style="margin-top: -30px;">
      <b-row md="12">
        <h5>Map Row:</h5>
        <b-col md="8">
          <label for="">Starts At:</label>
          <span type="text" style="margin-right: 20px;">{{ startRow }}</span>
          <label for="">Ends At:</label>
          <span type="text" style="margin-right: 20px;">{{ endRow }}</span>
          <md-button style="margin-top: -3px;" class="md-rose" @click="downloadExcelData()"
              >Export File</md-button
            >
        </b-col>
        <b-col md="0">
          <div class="text-white my-4 pt-2"></div>
        </b-col>
        <b-col md="0" class="">
          <div class="text-white my-4 pt-2"></div>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-table
        outlined
        striped
        hover
        :items="excelData"
        :fields="excelFields"
        bordered
        show-empty
        class="bg-white"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <b-col md="3" class="p-0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import commonHelper from "../utility/common.helper.utility";
export default {
  name: "ExcelDetails",
  props: ["excelRowDtl", "recordId", "formName", "excelProcess", "menuSubmenuIdExcelUploader"],
  data() {
    return {
      loader: false,
      startRow: 1,
      endRow: null,
      uploadExcelData: [],
      uploadExcelFields: [],
      showMapButton: true,
      currentPage: 1,
      perPage: 10,
      excelImportHdrId: null,
      totalRows: null,
      isBusy: true,
      menuSubmenuId: {
        menuId: null,
        submenuId: null
      },
      excelData: [],
      excelFields: [
        {
          key: 'r_number',
          label: 'Row Number'
        },
        {
          key: 's_number',
          label: 'Serial No.'
        },
        {
          key: 'activity_code',
          label: 'Activity Code'
        },
        {
          key: 'subcode',
          label: 'Subcode'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'uom',
          label: 'UOM'
        },
        {
          key: 'qty',
          label: 'Quantity'
        },
        {
          key: 'unit_price',
          label: 'Unit Price'
        },
        {
          key: 'upload_error_msg',
          label: 'Upload Error Msg'
        }
      ]
    };
  },
  watch: {
    currentPage: function() {
        this.getExcelData();
    },
    perPage: function() {
        this.currentPage = 1;
        this.getExcelData();
    }
  },
  mounted() {
    this.excelImportHdrId = this.excelRowDtl.excel_improt_hdr_id;
    this.menuSubmenuId = {
        menuId: this.menuSubmenuIdExcelUploader.menuId,
        submenuId: this.menuSubmenuIdExcelUploader.submenuId
    }
    this.getExcelData();
  },
  methods: {
    downloadExcelData() {
      let dataToDownload = [];
      for(let i of this.uploadExcelData) {
        dataToDownload.push({
          r_number: i.column1,
          s_number: i.column2,
          activity_code: i.column3,
          subcode: i.column4,
          description: i.column5,
          uom: i.column6,
          qty: i.column7,
          unit_price: i.column8,
        })
      }
      commonHelper.downloadExcel(dataToDownload, "rfp-bid");
    },
    getExcelData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        excel_import_hdr_id: this.excelImportHdrId,
        menu_id: this.menuSubmenuId.menuId,
        sub_menu_id: 0,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getExcelData", payload)
        .then((response) => {
          this.loader = false;
          this.isBusy = false;
          if (response.status === 200) {
            this.totalRows = response.data.data.total_elements;
            this.uploadExcelData = response.data.data.page;
            this.endRow = this.totalRows;
            this.mapColumns();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    mapColumns() {
      for(let i of this.uploadExcelData) {
        this.excelData.push({
          r_number: i.column1,
          s_number: i.column2,
          activity_code: i.column3,
          subcode: i.column4,
          description: i.column5,
          uom: i.column6,
          qty: i.column7,
          unit_price: i.column8,
          upload_error_msg: i.upload_error_msg
        })
      }
    }
  },
};
</script>
