<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div>
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label for="font">RFP Number</label>
            <md-input
              v-model="rfpHdrDetails.rfp_number"
              :title="rfpHdrDetails.rfp_number"
              disabled
            >
            </md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label for="font">Descrption</label>
            <md-input
              v-model="rfpHdrDetails.description"
              :title="rfpHdrDetails.description"
              disabled
            >
            </md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label>Start Date</label>
            <md-input
              v-model="rfpHdrDetails.start_date"
              :title="rfpHdrDetails.start_date"
              disabled
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label>End Date</label>
            <md-input
              v-model="rfpHdrDetails.end_date"
              :title="rfpHdrDetails.end_date"
              disabled
            ></md-input>
          </md-field>
        </div>
        <div style="margin-top: 20px; display: flex;" v-if="excluded">
          <b-form-checkbox
            v-model="excluded"
            :value="true"
            disabled
            :unchecked-value="false"
            style="display: inline"
          >
          </b-form-checkbox>
          <div>Excluded</div>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-field>
            <label>Bid Status</label>
            <md-input
              v-model="rfpHdrDetails.bidStatus"
              :title="rfpHdrDetails.bidStatus"
              readonly
              disabled
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-30">
          <md-button
            v-if="rfpHdrDetails.bidStatus !== 'SUBMIT'"
            @click="getOpenKmAuthenticateUploadDocument('UPLOAD')"
            class="md-raised md-rose"
            >Upload Document</md-button
          >
          <md-button
            v-if="rfpHdrDetails.bidStatus === 'SUBMIT'"
            @click="showHideViewDocumentModal(true)"
            class="md-raised md-rose"
            >Update Document</md-button
          >
          <md-button
            style="margin-left: 30px;"
            v-if="rfpHdrDetails.bidStatus === 'SUBMIT'"
            @click="getOpenKmAuthenticateUploadDocument('VIEW')"
            class="md-raised md-rose"
            >View Document</md-button
          >
        </div>
        <div class="md-layout-item md-small-size-100 md-size-10">
          <md-button
            class="md-raised md-success"
            @click="getUploadedDocDtl()"
            :disabled="
              rfpHdrDetails.bidStatus === null ||
                rfpHdrDetails.bidStatus === '' ||
                rfpHdrDetails.bidStatus === 'SUBMIT'
            "
            >Submit</md-button
          >
        </div>
        <br />
        <!-- <div
          class="md-layout-item md-small-size-100 md-size-20"
          style="margin-left: -15px;"
        >
          <b-button
            style="border: none; background-color: green; color: white; padding: 6px 30px; margin-top: 5px;"
            @click="openReviewModal()"
            >REVIEW & SUBMIT</b-button
          >
        </div> -->
        <div
          class="md-layout-item md-small-size-100 md-size-15"
          style="margin-left: 15px;"
        ></div>
      </div>
    </div>
    <p
      style="font-weight: bolder; margin-left: 25px; margin-top: 0px; margin-bottom: -10px;"
    >
      Attachments
    </p>
    <div style="padding: 10px" v-if="showDocumentModal">
      <uploadDocument
        :wfHdrId="documentId"
        :openKmAuth="openKmAuth"
        :propMenuId="menuSubmenuRfpHeader.menuId"
        :PropSubMenuId="menuSubmenuRfpHeader.submenuId"
        :approval_status="poHeaderDetails.approval_status"
        formName="rfpBoq"
      />
    </div>
    <p
      style="font-weight: bolder; margin-left: 25px; margin-top: -20px; margin-bottom: -10px;"
    >
      BOQ Files
    </p>
    <div style="padding: 10px" v-if="showDocumentModal">
      <uploadDocument
        :wfHdrId="documentId"
        :openKmAuth="openKmAuth"
        :propMenuId="menuSubmenuBoqExcel.menuId"
        :PropSubMenuId="menuSubmenuBoqExcel.submenuId"
        :approval_status="poHeaderDetails.approval_status"
        formName="rfpBoq"
      />
    </div>
    <p
      style="font-weight: bolder; margin-left: 25px; margin-top: -20px; margin-bottom: 0px;"
    >
      Locations
    </p>
    <div style="padding: 10px">
      <b-table
        striped
        hover
        bordered
        outlined
        sticky-header
        :items="rpfLocationData"
        :fields="rpfLocationField"
        show-empty
        class="bg-white"
      ></b-table>
    </div>
    <b-modal
      v-model="showUploadDocumentModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Upload Document"
      @hidden="showHideUploadDocumentModal(false)"
    >
      <uploadDocument
        v-if="rfpHdrDetails.bidStatus === 'SUBMIT'"
        :wfHdrId="bidHeaderId"
        :openKmAuth="openKmAuth"
        :propMenuId="menuSubmenuBidHeader.menuId"
        :PropSubMenuId="menuSubmenuBidHeader.submenuId"
        :approval_status="poHeaderDetails.approval_status"
        :formName="formName"
      />
      <uploadDocument
        v-if="rfpHdrDetails.bidStatus !== 'SUBMIT'"
        :wfHdrId="bidHeaderId"
        :openKmAuth="openKmAuth"
        :propMenuId="menuSubmenuBidHeader.menuId"
        :PropSubMenuId="menuSubmenuBidHeader.submenuId"
        :approval_status="poHeaderDetails.approval_status"
        :formName="formName"
      />
    </b-modal>
    <b-modal
      v-model="showViewDocumentModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="sm"
      title="View Document"
      @hidden="showHideViewDocumentModal(false)"
    >
      <div>
        <div style="margin: auto; margin-top: 100px;">
          <div>
            <p>
              Are you sure you want to update the documents? If yes, you need to
              upload revised documents
            </p>
          </div>
          <div>
            <md-button
              class="md-raised md-rose"
              @click="showHidedocumentViewModal('Yes')"
              >Yes</md-button
            >
            <md-button
              class="md-primary"
              @click="showHidedocumentViewModal('No')"
              >No</md-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import uploadDocument from "../../uploadDocument.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "RFPDetailsBOQFile",
  props: ["rowDetails"],
  components: {
    uploadDocument,
  },
  data() {
    return {
      loader: false,
      rfpHdrDetails: {},
      excluded: false,
      openKmAuth: null,
      bidHeaderId: 0,
      showUploadDocumentModal: false,
      poHeaderDetails: {
        approval_status: "DRAFT",
      },
      showDocumentModal: false,
      documentId: 0,
      termId: 0,
      summary: null,
      editors: {
        classic: ClassicEditor,
      },
      editorConfig: {
        readOnly: true,
        toolbar: [],
      },
      subMenuId: 0,
      showOpenTermsCondition: false,
      rpfLocationData: [],
      rpfLocationField: [
        {
          key: "location_name",
        },
      ],
      showViewDocumentModal: false,
      userId: null,
      uploadedDocuments: 0,
      formName: null,
      menuSubmenuRfpHeader: {
        menuId: null,
        submenuId: null,
      },
      menuSubmenuBidHeader: {
        menuId: null,
        submenuId: null,
      },
      menuSubmenuBoqExcel: {
        menuId: null,
        submenuId: null,
      },
    };
  },
  mounted() {
    this.setMenuSubmenuId();
    this.userId = JSON.parse(sessionStorage.getItem("userId"));
    if (this.rowDetails) {
      this.fillRecordForPoDetails(this.rowDetails);
      this.getOpenKmAuthenticate("attachment");
      this.getOpenKmAuthenticate("boqFiles");
    }
  },
  methods: {
    fillRecordForPoDetails(item) {
      this.rfpHdrDetails = {
        rfp_number: item.rfp_number,
        description: item.description,
        start_date: item.start_date,
        end_date: item.end_date,
        rfp_header_id: item.rfp_header_id,
        rfp_vendor_id: item.rfp_vendor_id,
        user_id: item.user_id,
        negotiation_hdr_id: item.negotiation_hdr_id,
        negotiation_vendor_id: item.negotiation_vendor_id,
      };
      this.getLocationDetails();
      this.termId = item.terms_id;
      if (this.termId === null) {
        this.termsFlag = true;
      }
      if (this.rfpHdrDetails.bidStatus === "SUBMIT") {
        this.editMode = false;
      }
    },
    getOpenKmAuthenticateUploadDocument(type) {
      if (this.rfpHdrDetails.bidStatus === "SUBMIT" && type === "UPLOAD") {
        this.showViewDocumentModal = true;
        this.formName = "rfpQuotation";
      } else if (this.rfpHdrDetails.bidStatus === "SUBMIT" && type === "VIEW") {
        this.showUploadDocumentModal = true;
        this.formName = "rfpQuotationView";
      } else if (
        this.rfpHdrDetails.bidStatus === "SUBMIT" &&
        type === "SUBMIT"
      ) {
        this.saveBoqFilesData();
      } else {
        this.formName = "rfpQuotation";
        this.saveBoqFilesData();
        this.loader = true;
        this.$store
          .dispatch("uploadDocument/getOpenKmAuthenticate")
          .then((response) => {
            this.loader = false;
            if (response.status === 200) {
              this.openKmAuth = response.data.data.token;
              this.showHideUploadDocumentModal(true);
            } else {
              alert(appStrings.DEPENDENTVSETMSG.DEFAULTMSG);
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    showHideUploadDocumentModal(flag) {
      this.showUploadDocumentModal = flag;
    },
    showHideDocumentModal(flag) {
      this.showDocumentModal = flag;
    },
    getOpenKmAuthenticate(type) {
      if (type === "attachment") {
        (this.documentId = this.rfpHdrDetails.rfp_header_id),
          (this.subMenuId = 0);
      }
      if (type === "boqFiles") {
        this.documentId = this.rfpHdrDetails.rfp_header_id;
      }
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.showHideDocumentModal(true);
          } else {
            alert(appStrings.DEPENDENTVSETMSG.DEFAULTMSG);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    closeTermsCondition(flag) {
      this.showOpenTermsCondition = flag;
    },
    getLocationDetails() {
      const payload = {
        _page: 0,
        _limit: 100,
        rfp_header_id: this.rfpHdrDetails.rfp_header_id,
        rfp_vendor_id: this.rfpHdrDetails.rfp_vendor_id,
        negotiation_hdr_id: this.rfpHdrDetails.negotiation_hdr_id,
      };
      this.loader = true;
      this.rpfLocationData = [];
      this.$store
        .dispatch("vendor/getRfpLocationDetailsBoq", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.rfpHdrDetails.bidStatus = result[0].status;
            this.rpfLocationData = result;
            this.bidHeaderId = result[0].bid_hdr_id ? result[0].bid_hdr_id : 0;
            this.totalLocationDetailsRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveBoqFilesData() {
      let vendorbiddetails = [];
      for (let i of this.rpfLocationData) {
        vendorbiddetails.push({
          boq_bid_id: 0,
          boq_id: 0,
          unit_price: 0,
          remarks: 0,
          location_id: i.location_id,
          exc_boq: 0,
        });
      }
      const payload = {
        bid_hdr_id:
          this.rfpHdrDetails.bidStatus === "SUBMIT" ? 0 : this.bidHeaderId,
        negotiation_hdr_id: this.rowDetails.negotiation_hdr_id,
        negotiation_vendor_id: this.rowDetails.negotiation_vendor_id,
        rfp_header_id: this.rowDetails.rfp_header_id,
        version: 0,
        status: "DRAFT",
        rfp_vendor_bid_boq_dtls: vendorbiddetails,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/postRfpBoqData", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            this.rfpHdrDetails.bidStatus = resp.data.data.status;
            if (this.rfpHdrDetails.bidStatus === "SUBMIT") {
              this.editMode = false;
            }
            const result = resp.data.data;
            this.bidHeaderId = result.bid_hdr_id;
            this.responseMsg = resp.data.message;
            // this.makeToast(this.responseMsg, "success");
            // this.eventBus.$emit("bidHeaderId", this.bidHeaderId);
            // this.getRfpBoqData();
            this.getLocationData();
            if (this.reviewAndSubmitFlag) {
              this.reviewAndSubmitFlag = false;
              this.showReviewModal = true;
            }
          } else {
            this.responseMsg = response.response.data.message;
            // this.makeToast(this.responseMsg, "danger");
          }
        })
        .catch((err) => {
          this.loader = false;
          //   this.makeToast(err.message, "danger");
        });
      this.openSaveModal = false;
    },
    // submitBid() {
    //   this.getUploadedDocDtl();
    //   if (this.uploadedDocuments === 0) {
    //     alert("Please upload Documents first");
    //   } else {
    //     const payload = {
    //       bid_hdr_id: this.bidHeaderId,
    //     };
    //     this.loader = true;
    //     this.$store
    //       .dispatch("vendor/postRfpBoqBidSubmit", payload)
    //       .then((resp) => {
    //         this.loader = false;
    //         if (resp.status === 200) {
    //           this.rfpHdrDetails.bidStatus = "SUBMIT";
    //           this.responseMsg = resp.data.message;
    //           this.makeToast(this.responseMsg, "success");
    //         } else {
    //           this.responseMsg = resp.data.message;
    //           this.makeToast(this.responseMsg, "danger");
    //         }
    //       })
    //       .catch((err) => {
    //         this.loader = false;
    //         this.makeToast(err.message, "danger");
    //       });
    //   }
    // },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: "Alert",
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-center",
        appendToast: true,
        toastClass: "custom-toast",
      });
    },
    showHideViewDocumentModal(flag) {
      this.showViewDocumentModal = flag;
    },
    showHidedocumentViewModal(flag) {
      if (flag === "No") {
        this.showViewDocumentModal = false;
      } else {
        this.showViewDocumentModal = false;
        this.showHideUploadDocumentModal(false);
        this.getOpenKmAuthenticateUploadDocument("SUBMIT");
      }
    },
    getUploadedDocDtl() {
      const payload = {
        _page: 0,
        _limit: 100,
        menu_id: this.menuSubmenuBidHeader.menuId,
        sub_menu_id: this.menuSubmenuBidHeader.submenuId,
        record_id: this.bidHeaderId,
        user_id: this.userId,
      };
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const payload = {
                bid_hdr_id: this.bidHeaderId,
                payment_terms_remarks: 'accepted',
                negotiation_vendor_id: this.rfpHdrDetails.negotiation_vendor_id,
              };
              this.loader = true;
              this.$store
                .dispatch("vendor/postRfpBoqBidSubmit", payload)
                .then((resp) => {
                  this.loader = false;
                  if (resp.status === 200) {
                    this.rfpHdrDetails.bidStatus = "SUBMIT";
                    this.responseMsg = resp.data.message;
                    this.makeToast(this.responseMsg, "success");
                  } else {
                    this.responseMsg = resp.data.message;
                    this.makeToast(this.responseMsg, "danger");
                  }
                })
                .catch((err) => {
                  this.loader = false;
                  this.makeToast(err.message, "danger");
                });
            } else {
              alert("Please Upload Document first");
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
      this.eventBus.$emit("totalUploadedDocumentRows", this.totalRows);
    },
    setMenuSubmenuId() {
      const menuRfpHeader = JSON.parse(sessionStorage.getItem("rfpHeader"));
      this.menuSubmenuRfpHeader = {
        menuId: menuRfpHeader.menu_id,
        submenuId: menuRfpHeader.sub_menu_id,
      };
      const menuBidHeader = JSON.parse(sessionStorage.getItem("rfpBidHdr"));
      this.menuSubmenuBidHeader = {
        menuId: menuBidHeader.menu_id,
        submenuId: menuBidHeader.sub_menu_id,
      };
      const menuBoqExcel = JSON.parse(sessionStorage.getItem("rfpBoqExcel"));
      this.menuSubmenuBoqExcel = {
        menuId: menuBoqExcel.menu_id,
        submenuId: menuBoqExcel.sub_menu_id,
      };
    },
  },
};
</script>
