import http from "@/services/httpServices";
import URL_UTILITY from "@/utility/url.utility";

const state = {
  list: {},
  // vendorId: sessionStorage.getItem("vendorId"),
};

const mutations = {
  setVendorId(state, vendorId) {
    state.vendorId = vendorId;
  },
  setVendorName(state, vendorName) {
    state.vendorName = vendorName;
  },
};

const actions = {
  setVendorId(context, vendorId) {
    context.commit("setVendorId", vendorId);
  },
  setVendorName(context, vendorName) {
    context.commit("setVendorName", vendorName);
  },
  getVendorInvoiceGridData(context, payload) {
    return http.getApi(URL_UTILITY.getvendorInvoiceGridDataUrl, payload);
  },
  getByIdTaxInvoiceDetails(context, ppVendorInvoiceId) {
    return http.getApi(
      URL_UTILITY.addEditTaxInvoiceDetailUrl + "/" + ppVendorInvoiceId
    );
  },
  addEditTaxInvoiceDetail(context, payload) {
    return http.postApi(URL_UTILITY.addEditTaxInvoiceDetailUrl, payload);
  },
  getVendorDashboardCount(context, payload) {
    return http.getApi(URL_UTILITY.getVendorDashboardCountUrl, payload);
  },
  getVendorDashboardCountGst(context, payload) {
    return http.getApi(URL_UTILITY.getVendorDashboardCountGstUrl, payload);
  },
  getRejectedInvoice(context, payload) {
    return http.getApi(URL_UTILITY.getRejectedInvoiceUrl, payload);
  },
  getGstrMismatch(context, payload) {
    return http.getApi(URL_UTILITY.getGstrMismatchUrl, payload);
  },
  getScheduleToPay(context, payload) {
    return http.getApi(URL_UTILITY.getScheduleToPayUrl, payload);
  },
  getPendingApprovals(context, payload) {
    return http.getApi(URL_UTILITY.getPendingApprovalsUrl, payload);
  },
  getNewSubmission(context, payload) {
    return http.getApi(URL_UTILITY.getNewSubmissionUrl, payload);
  },
  getVendorOsDetails(context, payload) {
    return http.getApi(URL_UTILITY.getVendorOsDetailsUrl, payload);
  },
  getMismatchDetails(context, payload) {
    return http.getApi(URL_UTILITY.getMismatchDetailsUrl, payload);
  },
  getGstrMissing(context, payload) {
    return http.getApi(URL_UTILITY.getGstrMissingUrl, payload);
  },
  generateVendorData(context, payload) {
    return http.postApi(URL_UTILITY.generateVendorDataUrl, payload);
  },
  getVendor() {
    return http.getApi(URL_UTILITY.getVendorUrl);
  },
  getVendorLedgerDetails(context, payload) {
    return http.getApi(URL_UTILITY.getVendorLedgerDetailsUrl, payload);
  },
  getVendorLedgerHdr(context, payload) {
    return http.getApi(URL_UTILITY.getVendorLedgerHdrUrl, payload);
  },
  getVendorRequestId() {
    return http.getApi(URL_UTILITY.getVendorLedgerUrl + "/requestId");
  },
  getVendorTemplateId() {
    return http.getApi(URL_UTILITY.getVendorLedgerUrl + "/templateId");
  },
  getVendorFmsUnitDetails() {
    return http.getApi(URL_UTILITY.getVendorFmsUnitDetailsUrl);
  },
  getVendorLeaseUnitDetails(context, payload) {
    return http.getApi(URL_UTILITY.getVendorLeaseUnitDetailsUrl, payload);
  },

  getVendorDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorDetailsUrl + "/" + payload.party_req_id
    );
  },

  getVendorAddressDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorAddDetailsUrl + "/" + payload.party_req_id
    );
  },

  addEditVendorAddressDetails(context, payload) {
    return http.postApi(URL_UTILITY.getVendorAddDetailsUrl, payload);
  },

  getVendorContactDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorContactDetailsUrl + "/" + payload.party_req_id
    );
  },
  addEditVendorContactDetails(context, payload) {
    return http.postApi(URL_UTILITY.getVendorContactDetailsUrl, payload);
  },

  getVendorBankDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorBankDetailsUrl + "/" + payload.party_req_id
    );
  },
  addEditVendorBankDetails(context, payload) {
    return http.postApi(URL_UTILITY.getVendorBankDetailsUrl, payload);
  },
  sendWorkflowProcess(context, payload) {
    return http.postApi(URL_UTILITY.sendWorkflowProcessUrl, payload);
  },
  sendWorkflowApproval(context, payload) {
    return http.postApi(URL_UTILITY.sendWorkflowApprovalUrl, payload);
  },
  getVendorInvoices(context, payload) {
    return http.postApi(URL_UTILITY.getVendorUrl + "/invoices", payload);
  },
  addEditVendorDetails(context, payload) {
    return http.postApi(URL_UTILITY.getVendorDetailsUrl + "/einvoice", payload);
  },
  getComplianceData() {
    return http.getApi(
      URL_UTILITY.getVendorDetailsUrl + "/compliance/meta-data"
    );
  },
  addEditComplianceDetails(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorDetailsUrl + "/compliance",
      payload
    );
  },
  getVendorComplianceDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorDetailsUrl +
        "/" +
        payload.party_req_id +
        "/compliance"
    );
  },
  gstnVerify(context, payload) {
    return http.postApi(URL_UTILITY.gstnVerifyUrl, payload);
  },
  // getUploadedDoc(context, payload) {
  //   return http.getApi(URL_UTILITY.getUploadedDocUrl, payload);
  // },
  getMenuSubMenuId() {
    return http.getApi(URL_UTILITY.getMenuSubMenuIdUrl);
  },
  getVendorDocumentDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorDetailsUrl +
        "/" +
        payload.party_req_id +
        "/uploaded-doc-list"
    );
  },
  getDefaultDataAdditionalDetails() {
    return http.getApi(URL_UTILITY.getDefaultDataAdditionalDetailsUrl);
  },
  addEditAdditionalDetails(context, payload) {
    return http.postApi(URL_UTILITY.addEditAdditionalDetailsUrl, payload);
  },
  getAdditionalDetailsById(context, payload) {
    return http.getApi(
      URL_UTILITY.addEditAdditionalDetailsUrl + "/" + payload.party_req_id
    );
  },
  validateBankDetails(context, payload) {
    return http.postApi(URL_UTILITY.validateBankDetailsUrl, payload);
  },
  getVendorPoDetails(context, payload) {
    return http.postApi(URL_UTILITY.getVendorUrl + "/po-details", payload);
  },
  getVendorPoLineDetailsById(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/po-details-by-Id",
      payload
    );
  },
  getVendorInvoiceLineDetailsById(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-invoices-by-id",
      payload
    );
  },
  getTermsCondition(context, payload) {
    return http.getApi(URL_UTILITY.getTermsConditionUrl + "/details", payload);
  },
  addEditTermsCondition(context, payload) {
    return http.postApi(
      URL_UTILITY.getTermsConditionUrl + "/update-party-req",
      payload
    );
  },
  getVendorPaymentData(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-payment-hdr",
      payload
    );
  },
  getVendorPaymentsDtlsById(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-payment-hdr-dtl",
      payload
    );
  },
  getPoNumberDetailsById(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-portal-po-by-vendor-id",
      payload
    );
  },

  getVendorBankDetailsById(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorBankDetailsUrl + "-details" + "/" + payload.bankId
    );
  },
  getVendorAddressDetailsById(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorAddDetailsUrl + "-details" + "/" + payload.addressId
    );
  },
  deleteOpenKmDoc(context, payload) {
    return http.deleteApi(
      URL_UTILITY.getDocumentDownloadUrl +
        "/" +
        payload.docDetailId +
        "?token=" +
        payload.token
    );
  },
  getPoLineDetailsById(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-portal-invoices-po-line-details",
      payload
    );
  },
  deleteAddressRecord(context, payload) {
    return http.deleteApi(
      URL_UTILITY.getVendorAddDetailsUrl + "-delete/" + payload.addressId
    );
  },
  deleteContactRecord(context, payload) {
    return http.deleteApi(
      URL_UTILITY.getVendorContactDetailsUrl +
        "-details-delete/" +
        payload.contactId
    );
  },
  deleteBankRecord(context, payload) {
    return http.deleteApi(
      URL_UTILITY.getVendorBankDetailsUrl + "-details-delete/" + payload.bankId
    );
  },
  addEditVendorInvoiceDetails(context, payload) {
    return http.postApi(URL_UTILITY.addEditVendorInvoiceDetailsUrl, payload);
  },
  getTaxInvoiceDetails(context, payload) {
    return http.postApi(URL_UTILITY.getTaxInvoiceDetailsUrl, payload);
  },
  getVendorInvoiceDetails(context, payload) {
    return http.postApi(
      URL_UTILITY.addEditVendorInvoiceDetailsUrl + "-hdr",
      payload
    );
  },
  getVendorInvoiceLineDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.addEditVendorInvoiceDetailsUrl + "/" + payload.invHdrId
    );
  },
  addEditVendorTaxDetails(context, payload) {
    return http.postApi(URL_UTILITY.addEditVendorInvoiceDetailsUrl, payload);
  },
  getTaxTypeValueSet(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/tax-detail-value-set",
      payload
    );
  },
  getInvoicePaymentDtls(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-invoice-payment-dtl",
      payload
    );
  },
  getPendingQtyforInvoice(context, payload) {
    return http.getApi(
      URL_UTILITY.addEditVendorInvoiceDetailsUrl +
        "-line-dtls-qty/" +
        payload.po_num
    );
  },
  getKeySummaryDtls(context, payload) {
    return http.getApi(URL_UTILITY.getSummaryKeyDtlsUrl, payload);
  },
  getVendorDisclosureDetails() {
    return http.getApi(URL_UTILITY.getVendorDisclosureDetailsUrl);
  },
  addEditVendorDisclosureDetails(context, payload) {
    return http.postApi(URL_UTILITY.addEditVendorDisclosureDetailsUrl, payload);
  },
  getLegalEntityVendorInvoice(context, payload) {
    return http.postApi(URL_UTILITY.getVendorUrl + "/legal-entity", payload);
  },
  getVendorGstnVendorInvoice(context, payload) {
    return http.postApi(URL_UTILITY.getVendorUrl + "/vendor-gstn", payload);
  },
  getCompanyGstnVendorInvoice(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + "/company-gstn", payload);
  },
  getTaxTypeVendorInvoice(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + "/tax-type", payload);
  },
  getTaxRateVendorInvoice(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + "/tax-rate", payload);
  },
  getCurrencyVendorInvoice(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + "/currency-code", payload);
  },
  invoiceNumberValidation(context, payload) {
    return http.getApi(URL_UTILITY.invoiceNumberValidationUrl, payload);
  },
  getTermsConditionById(context, payload) {
    return http.getApi(
      URL_UTILITY.addEditVendorDisclosureDetailsUrl + "/" + payload.party_req_id
    );
  },
  getAdvanceReportDtls(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/open-advance-detail",
      payload
    );
  },
  addSubmissionDate(context, payload) {
    return http.postApi(URL_UTILITY.addSubmissionDateUrl, payload);
  },
  getAdhocTaxDtls(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + "/adhoc-tax-detail", payload);
  },
  getAdvanceAdjDetails(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + "/advance-adjusted", payload);
  },
  downloadInvoiceReport(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/excel-invoice-data",
      payload
    );
  },
  validateCurrencyExchangeRate(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorUrl + "/exchange-rate-currency-manual",
      payload
    );
  },
  vendorLocationExpenseType(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorUrl + "/ssn-cost-centre-and-account",
      payload
    );
  },
  downloadPaymentReport(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/excel-payment-data",
      payload
    );
  },
  downloadAdvanceReport(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/excel-open-advance-data",
      payload
    );
  },
  isRecordIdAvail(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorUrl +
        "/poReqData/" +
        payload.poHdrId +
        "/" +
        "?revision_num=" +
        payload.revisionNo
    );
  },
  poDetailSubmitRequest(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/po-report-request",
      payload
    );
  },
  getSubmitRequest(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorUrl +
        "/reqStatus/" +
        "?request_id=" +
        payload.requestId
    );
  },
  downloadReport(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorUrl + "/download/" + payload.requestId
    );
  },
  getSecurityDepositData(context, payload) {
    return http.getApi(URL_UTILITY.getVendorSecurityUrl +'-details', payload);
  },
  getRecoveryInvoiceData(context,payload){
    return http.getApi(URL_UTILITY.getRecoveryInvoiceUrl + '-invoices',payload)
  },
  isEmployeeAvail(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + "/manual-invoice-emp-search");
  },
  scrapeInvoice(context, payload) {
    return http.postApi(URL_UTILITY.deleteScrapInvoice, payload);
  },
  getRfpGridData(context, payload) {
    return http.getApi(URL_UTILITY.getRfpDetails + "/rfp-search", payload);
  },
  getRfpLocationDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + "/rfp-location-search",
      payload
    );
  },
  getRfpLineData(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + `/rfp-item-dtl/${payload.rfp_header_id}`,
      payload
    );
  },
  postRfpItemData(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-bid-dtl`, payload);
  },
  getRfpItemData(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + `/rfp-bid-dtl/${payload.bid_hdr_id}`
    );
  },
  getRfpItemAttributeLineData(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + `/rfp-item-attribute-dtl/${payload.itemId}`
    );
  },
  postRfpItemAttributeData(context, payload) {
    return http.postApi(
      URL_UTILITY.getRfpDetails + `/rfp-attribute-bid-dtl`,
      payload
    );
  },
  getRfpItemAttributeData(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + `/rfp-attribute-bid-dtl/${payload.bidId}`
    );
  },
  postRfpBidSubmit(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-bid-submit`, payload);
  },
  postCopyBid(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-copy-bid`, payload);
  },
  reviewBid(context, payload) {
    return http.getApi(URL_UTILITY.getRfpDetails + `/rfp-review-bid`, payload);
  },
  isInvDuplicateOracle(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorUrl +
        "/inv-duplicate-ora" +
        "?vendor_id=" +
        payload.vendor_id +
        "&invoice_num=" +
        payload.inv_number
    );
  },
  postExcludeLocation(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-exc-loc`, payload);
  },
  getExcludeLocation(context, payload) {
    return http.getApi(URL_UTILITY.getRfpDetails + `/rfp-review-bid/${payload.rfp_vendor_bid_exc_loc_id}`);
  },
  getRfpLineBoqData(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + `/rfp-boq-dtl/${payload.rfp_header_id}`,
    );
  },
  postRfpBoqData(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-bid-boq-dtl`, payload);
  },
  getRfpBoqData(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + `/rfp-bid-boq-dtl/${payload.bid_hdr_id}`
    );
  },
  getRfpBoqAttributeLineData(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + `/rfp-boq-attribute-dtl/${payload.boq_id}`
    );
  },
  postRfpBoqAttributeData(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-boq-attribute-bid-dtl`, payload);
  },
  getRfpBoqAttributeData(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + `/rfp-boq-attribute-bid-dtl/${payload.boq_bid_id}`
    );
  },
  postCopyBoqBid(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-boq-copy-bid`, payload);
  },
  reviewBoqBid(context, payload) {
    return http.getApi(URL_UTILITY.getRfpDetails + `/rfp-boq-review-bid`, payload);
  },
  postRfpBoqBidSubmit(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-boq-bid-submit`, payload);
  },
  postExcludeBoqLocation(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-boq-exc-loc`, payload);
  },
  getExcludeBoqLocation(context, payload) {
    return http.getApi(URL_UTILITY.getRfpDetails + `/rfp-boq-exc-loc/${payload.rfp_vendor_bid_exc_loc_id}`);
  },
  getTermId(context, payload) {
    return http.getApi(URL_UTILITY.getRfpDetails + `/rfp-terms-and-condition/${payload.term_id}`);
  },
  getRfpVersion(context, payload) {
    return http.getApi(URL_UTILITY.getRfpDetails + `/rfp-version-dropdown`, payload);
  },
  postRfpVersion(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-copy-version-bid`, payload);
  },
  deleteNewInvoiceDetails(context, payload) {
    return http.postApi(URL_UTILITY.getVendorPortalExternal + `/invoice-line-delete`, payload);
  },
  getInvoiceRequestId(context,payload){
    return http.getApi(URL_UTILITY.getRequestTemplateId +"/requestId", payload)
  },
  postTermsAndConditionsAccept(context, payload) {
    return http.getApi(URL_UTILITY.getRfpDetails + `/rfp-t-and-c-accaptance`, payload);
  },
  getRfpLocationDetailsBoq(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + "/rfp-location-search-boq",
      payload
    );
  },
  copyItemAttributeData(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-bid-copy-atr`, payload);
  },
  copyBoqAttributeData(context, payload) {
    return http.postApi(URL_UTILITY.getRfpDetails + `/rfp-bid-copy-atr-boq`, payload);
  },
  getVendorBankAccount(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + `/vendor-bank-details`, payload);
  },
  getVendorPaymentAdviceData(context,payload){
    return http.getApi(URL_UTILITY.getPaymentAdviceUrl ,payload)
  },
  getGlobalRequestIdId(context,payload){
    return http.getApi(URL_UTILITY.getGlobalRequestIdIdUrl ,payload)
  },
  deleteTaxForDifferentGstn(context, payload) {
    return http.postApi(URL_UTILITY.getVendorPortalExternal + `/delete-Invoice-line-tax-delete/${payload.vendorInvoiceHdrId}`, payload);
  },
  getUserManagementData(context, payload) {
    return http.getApi(URL_UTILITY.getVendorPortalExternal + `/vendor-user-manual/oraPartyId/${payload.oraPartyId}`);
  },
  postUserManagementData(context, payload) {
    return http.postApi(URL_UTILITY.getVendorPortalExternal + `/vendor-user-manual/email/${payload.oraPartyId}`, payload);
  },
  putUserManagementData(context, payload) {
    return http.postApi(URL_UTILITY.getVendorPortalExternal + "/vendor-user-manual/update", payload);
  },
  getLastUploadTdsCertificate() {
    return http.getApi(URL_UTILITY.getVendorsUrl + "/tds-cert/last-date");
  },
  getVendorTdsCertificate(context, payload) {
    return http.getApi(URL_UTILITY.getVendorsUrl + "/tds-cert", payload);
  },
  getVendorTdsCertificateById(context, payload) {
    return http.getApi(URL_UTILITY.getVendorsUrl + `/tds-cert/${payload.tds_cert_hdr_id}`);
  },
  addUploadExcelList(context, payload) {
    return http.postApi(URL_UTILITY.excelUploadUrl + `/postExcel`, payload);
  },
  addUploadRfpExcelList(context, payload) {
    const url = URL_UTILITY.excelUploadUrl + "/saveDataRfpTmp";
    return http.postApi(
      url +
        "?excel_import_hdr_id=" +
        payload.excel_import_hdr_id +
        "&menu_id=" +
        payload.menu_id +
        "&sub_menu_id=" +
        payload.sub_menu_id +
        "&rfp_header_id=" +
        payload.rfp_header_id +
        "&negotiation_vendor_id=" +
        payload.negotiation_vendor_id
    );
  },
  getExcelData(context, payload) {
    return http.getApi(
      URL_UTILITY.excelUploadUrl + '/getExcelTempData',
      payload
    );
  },
  postVendorQuestion(context, payload) {
    return http.postApi(
      URL_UTILITY.getRfpDetails + '/rfp-vendor-question',
      payload
    );
  },
  getVendorQuestion(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + '/rfp-conversation-dtl',
      payload
    );
  },
  getRfpAwardDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getRfpDetails + `/rfp-vendor-award-remarks-dtl/${payload.rfpHdrId}`
    );
  },
  postRfpAwardDetails(context, payload) {
    return http.postApi(
      URL_UTILITY.getRfpDetails + `/rfp-vendor-award-remarks-dtl`, payload
    );
  },
  getRfpVendorGstn(context, payload) {
    return http.postApi(
      URL_UTILITY.getRfpDetails + `/rfp-vendor-gstn`, payload
    );
  },
};

const getters = {};

const vendor = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default vendor;
