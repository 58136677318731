<template>
  <div class="md-layout">
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="font">RFP Number</label>
              <md-input v-model="rpfNum"> </md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-15"
            style="min-width:10%;"
          >
            <md-field>
              <label for="rfpStatus">RFP Status</label>
              <div>
                <md-select
                  v-model="rfpStatus"
                  name="rfpStatus"
                  class="rfpStatus"
                >
                <md-option value="OPEN">Open</md-option>
                  <md-option value="OPEN FOR BIDDING">Open For Bid</md-option>
                  <md-option value="CLOSED FOR BIDDING"
                    >Closed For Bid</md-option
                  >
                  <md-option value="CLOSED">Closed</md-option>
                </md-select>
                <span
                  style="margin-top: -15px; font-weight: bolder;"
                  aria-hidden="true"
                  class="clearable-icon"
                  v-if="rfpStatus"
                  @click="clearFilters('rfpStatus')"
                  >×</span
                >
              </div>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label for="movie">Bid Status</label>
              <md-select v-model="bidStatus" name="bidStatus" class="bidStatus">
                <md-option value="OPEN">Open</md-option>
                <md-option value="CLOSED">Closed</md-option>
              </md-select>
              <span
                style="margin-top: -15px; font-weight: bolder;"
                aria-hidden="true"
                class="clearable-icon"
                v-if="bidStatus"
                @click="clearFilters('bidStatus')"
                >×</span
              >
            </md-field>
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-15 DateFormate ml-4 "
          >
            <input
              style="border-radius: 5px;width: 95%; height: 30px; border: 1px solid grey; margin-left: -10px"
              type="datetime-local"
              format="dd-MMM-yyyy"
              v-model="startDate"
              :title="startDate"
              step="1"
            />
            <span
              style="margin-left: -13px; font-weight: bolder;"
              aria-hidden="true"
              class="clearable-icon-date"
              v-if="startDate"
              @click="clearFilters('startDate')"
              >×</span
            >
          </div>
          <div
            style="margin-left:-1%;"
            class="md-layout-item md-small-size-100 md-size-15 DateFormate"
          >
            <input
              style="border-radius: 5px;width: 95%; height: 30px; border: 1px solid grey;   margin-left: -15px"
              type="datetime-local"
              format="dd-MMM-yyyy"
              v-model="endDate"
              :title="endDate"
              :disabled="!startDate"
              step="1"
            />
            <span
              style="margin-left: -13px; font-weight: bolder;"
              aria-hidden="true"
              class="clearable-icon-date"
              @click="clearFilters('endDate')"
              v-if="endDate"
              >×</span
            >
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-20"
            style="margin-left: -30px; margin-top: -6px;"
          >
            <div>
              <md-button
                class="md-raised md-success search-btn mt-4"
                @click="getRfpGridData()"
                >Search</md-button
              >
              <!-- @click="isVendorApproved()" -->
              <md-button
                style="margin-left: 20px"
                class="md-raised md-info search-btn mt-4"
                @click="clearSearch()"
                >Clear</md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout-item">
          <b-skeleton-table
            v-if="loading"
            :rows="10"
            :columns="5"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
          <b-table
            v-if="!loading"
            striped
            hover
            outlined
            bordered
            sticky-header
            :items="rpfGridData"
            :fields="rpfGridField"
            show-empty
            class="bg-white"
            style="max-height:calc(100vh - 220px)"
          >
            <template #cell(submit)="data">
              <md-button
                v-if="
                  data.item.terms_id &&
                    (data.item.accaptance_flag === null ||
                      data.item.accaptance_flag === false)
                "
                :class="
                  data.item.bid_status === 'OPEN'
                    ? 'md-raised md-warning search-btn'
                    : 'md-raised md-primary search-btn'
                "
                @click="acceptTermsAndConditions(true, data.item)"
                >{{
                  data.item.bid_status === "OPEN" ? " Bid" : "View Bid"
                }}</md-button
              >
              <md-button
                v-else
                :class="
                  data.item.bid_status === 'OPEN'
                    ? 'md-raised md-warning search-btn'
                    : 'md-raised md-primary search-btn'
                "
                @click="showHideBidDetails(true, data.item)"
                >{{
                  data.item.bid_status === "OPEN" ? " Bid" : "View Bid"
                }}</md-button
              >
            </template>
            <template #cell(description)="data"
              ><span
                style="cursor:context-menu;"
                :title="data.item.description"
                >{{ truncateComment(data.item.description, 15) }}</span
              ></template
            >
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
          <div class="md-layout pagination-count">
            <div style="width:18%;">
              <div v-if="rpfGridData.length && totalRows <= perPage">
                <p>
                  Showing
                  {{
                    totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1
                  }}
                  to {{ totalRows }} of {{ totalRows }} entries
                </p>
              </div>
              <div v-else-if="rpfGridData.length">
                <p>
                  Showing {{ perPage * (currentPage - 1) + 1 }} to
                  {{
                    perPage * currentPage >= totalRows
                      ? totalRows
                      : perPage * currentPage
                  }}
                  of {{ totalRows }}
                  entries
                </p>
              </div>
            </div>
            <!-- <div v-if="rpfGridData.length" style="margin-top:-0.5%; width:7%">
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="perPage">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div> -->
            <!-- <div style="color:red; margin-left:50%;">
              <span>Click on the Field Header for more information</span>
            </div> -->
          </div>
        </div>
      </md-card>
    </div>
    <b-modal
      v-model="showRfpDetails"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="RFP Item Details"
      @hidden="showHideRfpDetails(false, null)"
    >
      <RFPDetails :rowDetails="rowDetails" />
    </b-modal>
    <b-modal
      v-model="showRfpBoqDetails"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="RFP BOQ Details"
      @hidden="showHideRfpBoqDetails(false, null)"
    >
      <RFPDetailsBOQ :rowDetails="rowDetails" />
    </b-modal>
    <b-modal
      v-model="showTermsAndConditions"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="md"
      title="Terms & Conditions"
      @hidden="showHideTermsAndConditionsModal(false, null)"
    >
      <template>
        <div style="width: 100%; height: auto; border: 2px solid">
          <uploadDocument
            :wfHdrId="termId"
            :openKmAuth="openKmAuth"
            :propMenuId="menuSubmenuTermsAndCondition.menuId"
            :PropSubMenuId="menuSubmenuTermsAndCondition.submenuId"
            :approval_status="poHeaderDetails.approval_status"
            formName="rfp"
          />
        </div>

        <b-row md="12" class="mt-4">
          <b-col md="12">
            <label for="filter">Terms & Conditions</label>
            <vue-ckeditor
              type="classic"
              :editors="editors"
              :config="editorConfig"
              v-model="summary"
              :title="summary"
            ></vue-ckeditor>
          </b-col>
        </b-row>
        <div>
          <b-row style="margin-top: 50px; margin-left: 10px; display: flex;">
            <b-form-checkbox
              v-model="checkboxFlag"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
            <span style="font-size: 14px; font-weight: bold"
              >I hereby declare that the information given above and in the
              enclosed documents is true to the best of my knowledge and belief
              and nothing has been concealed therein.</span
            >
          </b-row>
          <b-row>
            <md-button
              class="md-primary"
              :disabled="!checkboxFlag"
              @click="submitBtn()"
              >Accept</md-button
            >
          </b-row>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="showRfpBoqFilesDetails"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="RFP BOQ Files Details"
      @hidden="showHideRfpBoqFilesDetails(false, null)"
    >
      <RFPDetailsBOQFile :rowDetails="rowDetails" />
    </b-modal>
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
// import DatePicker from "vue2-datepicker";
import RFPDetails from "./RFPDetails.vue";
// import HeaderSummary from "../HeaderSummary.vue";
import URL_UTILITY from "../../../utility/url.utility";
import RFPDetailsBOQ from "./RFPDetailsBOQ.vue";
import VueCkeditor from "vue-ckeditor5";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadDocument from "../../uploadDocument.vue";
import RFPDetailsBOQFile from "./RFPDetailsBOQFile.vue";

export default {
  components: {
    // DatePicker,
    RFPDetails,
    // HeaderSummary,
    RFPDetailsBOQ,
    VueCkeditor,
    "vue-ckeditor": VueCkeditor.component,
    uploadDocument,
    RFPDetailsBOQFile,
  },
  name: "RFP",
  watch: {
    currentPage: function() {
      this.getRfpGridData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRfpGridData();
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: commonHelper.getPageOption(),
      },
      perPage: 10,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      loader: false,
      loading: false,
      rpfNum: null,
      startDate: null,
      endDate: null,
      rpfGridData: [],
      rpfGridField: [
        {
          key: "rfp_number",
          label: "RFP Number",
        },
        // {
        //   key: "rfp_status",
        //   label: "RFP Status",
        // },
        {
          key: "description",
        },
        {
          key: "start_date",
        },
        {
          key: "end_date",
        },
        {
          key: "bid_status",
        },
        {
          key: "submit",
        },
      ],
      totalPayload: null,
      showRfpDetails: false,
      rowDetails: null,
      userId: null,
      rfpStatus: "OPEN",
      showRfpBoqDetails: false,
      rfpStatusType: [
        { value: 'OPEN', text: "OPEN" },
        { value: "CLOSED", text: "CLOSED" },
        { value: "OPEN FOR BIDDING", text: "OPEN FOR BIDDING" },
        { value: "CLOSED FOR BIDDING", text: "CLOSED FOR BIDDING" },
      ],
      bidStatus: null,
      showTermsAndConditions: false,
      summary: null,
      editors: {
        classic: ClassicEditor,
      },
      editorConfig: {
        readOnly: true,
        toolbar: [],
      },
      checkboxFlag: false,
      tncAcceptFlag: false,
      selectedItem: {},
      termId: null,
      currentPageIndex: null,
      showOpenUploadDocModal: false,
      dynamicData: null,
      uploadedFileName: null,
      docDtlId: null,
      authTokenPreview: null,
      openKmAuth: null,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      rfpHeaderId: null,
      rfpVendorId: null,
      showDocumentModal: true,
      documentId: 0,
      poHeaderDetails: {
        approval_status: "DRAFT",
      },
      showRfpBoqFilesDetails: false,
      menuSubmenuTermsAndCondition: {
        menuId: null,
        submenuId: null,
      },
    };
  },
  mounted() {
    this.getMenuSubMenuId();
    this.userId = JSON.parse(sessionStorage.getItem("userId"));
    this.getRfpGridData();
  },
  methods: {
    showHideRfpDetails(flag, item) {
      this.showRfpDetails = flag;
      this.rowDetails = item;
      this.getRfpGridData();
    },
    showHideRfpBoqDetails(flag, item) {
      this.showRfpBoqDetails = flag;
      this.rowDetails = item;
      this.getRfpGridData();
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    clearSearch() {
      (this.rfpStatus = null), (this.rpfNum = null);
      this.startDate = null;
      this.endDate = null;
      this.bidStatus = null;
    },
    getRfpGridData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        rfp_number: this.rpfNum,
        rfp_status: this.rfpStatus,
        start_date: this.startDate,
        end_date: this.endDate,
        user_id: this.userId,
        bid_status: this.bidStatus,
      };
      this.loading = true;
      this.$store
        .dispatch("vendor/getRfpGridData", payload)
        .then((resp) => {
          if (resp.data.status === 200) {
            const result = resp.data.data.page;
            this.rpfGridData = result;
            this.totalRows = resp.data.data.total_elements;
            this.loading = false;
          } else {
            alert("Some Error occured");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showHideBidDetails(flag, item) {
      if (item.rfp_basis === "BOQ") {
        this.showHideRfpBoqDetails(flag, item);
      } else if (item.rfp_basis === "boqFiles") {
        this.showHideRfpBoqFilesDetails(flag, item);
      } else {
        this.showHideRfpDetails(flag, item);
      }
    },
    clearFilters(value) {
      if (value === "rfpStatus") {
        this.rfpStatus = null;
      } else if (value === "bidStatus") {
        this.bidStatus = null;
      } else if (value === "startDate") {
        this.startDate = null;
        this.endDate = null;
      } else if (value === "endDate") {
        this.endDate = null;
      }
    },
    acceptTermsAndConditions(flag, item) {
      this.termId = item.terms_id;
      this.getOpenKmAuthenticate();
      this.rfpHeaderId = item.rfp_header_id;
      this.rfpVendorId = item.rfp_vendor_id;
      this.getTermsAndConditions();
      this.selectedItem = item;
      this.showTermsAndConditions = flag;
    },
    showHideTermsAndConditionsModal() {
      this.showTermsAndConditions = false;
    },
    submitBtn() {
      this.tncAcceptFlag = true;
      this.showTermsAndConditions = false;
      this.acceptTermsAndConditionsButton();
      this.showHideBidDetails(true, this.selectedItem);
      this.getRfpGridData();
    },
    getOpenKmAuthenticate() {
      this.documentId = this.termId;
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
          } else {
            alert(appStrings.DEPENDENTVSETMSG.DEFAULTMSG);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTermsAndConditions() {
      const payload = {
        term_id: this.termId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getTermId", payload)
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.summary = response.data.data.rfp_t_and_c;
            setTimeout(() => {
              this.summary = JSON.parse(JSON.stringify(this.summary));
            }, 500);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    acceptTermsAndConditionsButton() {
      const payload = {
        rfp_header_id: this.rfpHeaderId,
        rfp_vendor_id: this.rfpVendorId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/postTermsAndConditionsAccept", payload)
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.summary = response.data.data.rfp_t_and_c;
            setTimeout(() => {
              this.summary = JSON.parse(JSON.stringify(this.summary));
            }, 500);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    showHideRfpBoqFilesDetails(flag, item) {
      this.showRfpBoqFilesDetails = flag;
      this.rowDetails = item;
      this.getRfpGridData();
    },
    getMenuSubMenuId() {
      this.loader = true;
      this.$store
        .dispatch("vendor/getMenuSubMenuId")
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            result.forEach((ele) => {
              if (ele.menu_name === "RFP") {
                if (ele.sub_menu_name === "RFP HEADER DOCUMENTS") {
                  sessionStorage.setItem("rfpHeader", JSON.stringify(ele));
                } else if (ele.sub_menu_name === "RFP TERMS AND CONDITION") {
                  sessionStorage.setItem(
                    "rfpTermsAndConditions",
                    JSON.stringify(ele)
                  );
                } else if (ele.sub_menu_name === "RFP BOQ UPLOAD EXCEL") {
                  sessionStorage.setItem(
                    "rfpBoqUploadExcel",
                    JSON.stringify(ele)
                  );
                } else if (ele.sub_menu_name === "RFP BID HDR DOC") {
                  sessionStorage.setItem("rfpBidHdr", JSON.stringify(ele));
                } else if (ele.sub_menu_name === "RFP BOQ EXCEL DOC") {
                  sessionStorage.setItem("rfpBoqExcel", JSON.stringify(ele));
                } else if (ele.sub_menu_name === "RFP BOQ ROUND DOC") {
                  sessionStorage.setItem("rfpBoqRound", JSON.stringify(ele));
                } else if (
                  ele.sub_menu_name === "BOQ EXCEL UPLOADER AT VENDOR SIDE"
                ) {
                  sessionStorage.setItem(
                    "rfpExcelUploader",
                    JSON.stringify(ele)
                  );
                }
              }
            });
            this.setMenuSubmenuId();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setMenuSubmenuId() {
      const menuItemTermsAndCondition = JSON.parse(
        sessionStorage.getItem("rfpTermsAndConditions")
      );
      this.menuSubmenuTermsAndCondition = {
        menuId: menuItemTermsAndCondition.menu_id,
        submenuId: menuItemTermsAndCondition.sub_menu_id,
      };
    },
    getLocalDateTimes() {
  let now = new Date();
  let past = new Date();
  past.setMonth(past.getMonth() - 6);

  const formatDateTime = (date) => {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let day = String(date.getDate()).padStart(2, "0");
    let hours = String(date.getHours()).padStart(2, "0");
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let seconds = String(date.getSeconds()).padStart(2, "0");
    
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  return {
    currentDate: formatDateTime(now),
    sixMonthsBackDate: formatDateTime(past)
  };
},
formatDateTime(isoDateTime) {
  let date = new Date(isoDateTime);
  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0");
  let year = date.getFullYear();
  let hours = String(date.getHours()).padStart(2, "0");
  let minutes = String(date.getMinutes()).padStart(2, "0");
  let seconds = String(date.getSeconds()).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}
  },
};
</script>
<style></style>
